import { createSelector } from '@ngrx/store';
import { Role } from 'src/app/commons/models/role.model';

import { selectRoleState } from '../reducers';
import { RoleState } from '../reducers/role.reducer';
import { Permission } from './../../commons/models/role.model';

// Role Selectors

export const getRolesTableState = createSelector(
  selectRoleState,
  (state: RoleState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction } }
);

export const getRoles = createSelector(
  selectRoleState,
  (state: RoleState) => state.list ? state.list.map(dto => new Role(dto)) : []
);

export const getTotalRoles = createSelector(
  getRolesTableState,
  (tableState) => tableState.total
);

export const getRolesCurrentPage = createSelector(
  getRolesTableState,
  (tableState) => tableState.currentPage
);

export const getRolesPerPage = createSelector(
  getRolesTableState,
  (tableState) => tableState.perPage
);

export const getRolesOrder = createSelector(
  getRolesTableState,
  (tableState) => tableState.order
);

export const getRolesDirection = createSelector(
  getRolesTableState,
  (tableState) => tableState.direction
);

export const getRoleDialogId = createSelector(
  selectRoleState,
  (state: RoleState) => state.dialogId
);

export const getPermissions = createSelector(
  selectRoleState,
  (state: RoleState) => state.permissions ? state.permissions.map(dto => new Permission(dto)) : []
);
