import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './commons/guards/auth.guard';
import { UnAuthGuard } from './commons/guards/unauth.guard';

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import('./modules/home/home.module').then(mod => mod.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path: "login",
    loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule),
    canActivate: [UnAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuard, UnAuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
