import { createAction, props } from '@ngrx/store';

import { RegistrationDTO, RegistrationFilters } from '../../commons/models/user.model';
import { Registration } from './../../commons/models/user.model';

export const loadRegistrations = createAction('[Registrations] Load registrations', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: RegistrationFilters }>());
export const loadRegistrationsCompleted = createAction('[Registrations] Load registrations Completed', props<{ registrations: RegistrationDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: RegistrationFilters }>());
export const loadRegistrationsFailed = createAction('[Registrations] Load registrations Failed', props<{ error: any }>());

export const changePage = createAction('[Registrations] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Registrations] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Registrations] Change filters', props<{ filters: RegistrationFilters }>());

export const showRegitration = createAction('[Registrations] Show registration', props<{ registration: Registration }>());
export const registrationDialogOpened = createAction('[Registrations] Detail dialog opened', props<{ dialogId: string }>());
export const closeRegistrationDialog = createAction('[Registrations] Close detail dialog');

export const exportRegistrations = createAction('[Registrations] Export registrations', props<{ filters?: RegistrationFilters }>());
export const exportRegistrationsCompleted = createAction('[Registrations] Export registrations completed', props<{ file: Blob }>());
export const exportRegistrationsFailed = createAction('[Registrations] Export registrations failed', props<{ error: any }>());

export const downloadAutocertificazione = createAction('[Registrations] Download autocertificazione', props<{ registration: RegistrationDTO }>());
export const downloadAutocertificazioneCompleted = createAction('[Registrations] Download autocertificazione completed', props<{ file: Blob, filename?: string }>());
export const downloadAutocertificazioneFailed = createAction('[Registrations] Download autocertificazione failed', props<{ error: any }>());

export const downloadDocumentoRiconoscimento = createAction('[Registrations] Download documento di riconoscimento', props<{ registration: RegistrationDTO }>());
export const downloadDocumentoRiconoscimentoCompleted = createAction('[Registrations] Download documento di riconoscimento completed', props<{ file: Blob, filename?: string }>());
export const downloadDocumentoRiconoscimentoFailed = createAction('[Registrations] Download documento di riconoscimento failed', props<{ error: any }>());

export const downloadCodiceFiscale = createAction('[Registrations] Download codice fiscale', props<{ registration: RegistrationDTO }>());
export const downloadCodiceFiscaleCompleted = createAction('[Registrations] Download codice fiscale completed', props<{ file: Blob, filename?: string }>());
export const downloadCodiceFiscaleFailed = createAction('[Registrations] Download codice fiscale failed', props<{ error: any }>());

export const downloadCopiaBonifico = createAction('[Registrations] Download copia bonifico', props<{ registration: RegistrationDTO }>());
export const downloadCopiaBonificoCompleted = createAction('[Registrations] Download copia bonifico completed', props<{ file: Blob, filename?: string }>());
export const downloadCopiaBonificoFailed = createAction('[Registrations] Download copia bonifico failed', props<{ error: any }>());
