import { createAction, props } from '@ngrx/store';

import { Role, RoleDTO } from '../../commons/models/role.model';
import { PermissionDTO } from './../../commons/models/role.model';

export const loadRoles = createAction('[Roles] Load roles', props<{ page: number, perPage: number, order?: string, direction?: string }>());
export const loadRolesCompleted = createAction('[Roles] Load roles Completed', props<{ roles: RoleDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string }>());
export const loadRolesFailed = createAction('[Roles] Load roles Failed', props<{ error: any }>());

export const changePage = createAction('[Roles] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Roles] Change sort', props<{ order: string, direction: string }>());

export const editRole = createAction('[Roles] Edit role', props<{ role: Role }>());
export const roleDialogOpened = createAction('[Roles] Detail dialog opened', props<{ dialogId: string }>());
export const closeRoleDialog = createAction('[Roles] Close detail dialog');

export const saveRole = createAction('[Roles] Save role', props<{ role: Role }>());
export const saveRoleCompleted = createAction('[Roles] Save role completed', props<{ role: RoleDTO }>());
export const saveRoleFailed = createAction('[Roles] Save role failed', props<{ error: any }>());

export const deleteRole = createAction('[Roles] Delete role', props<{ role: Role }>());
export const deleteRoleCompleted = createAction('[Roles] Delete role completed', props<{ role: RoleDTO }>());
export const deleteRoleCancelled = createAction('[Roles] Delete role cancelled');
export const deleteRoleFailed = createAction('[Roles] Delete role failed', props<{ error: any }>());

export const loadPermissions = createAction('[Permission] Load permissions');
export const loadPermissionsCompleted = createAction('[Permission] Load permissions Completed', props<{ permissions: PermissionDTO[] }>());
export const loadPermissionsFailed = createAction('[Permission] Load permissions Failed', props<{ error: any }>());
