import { createAction, props } from '@ngrx/store';

import {
  AgreementDTO,
  CertificateTypeDTO,
  ConventionCategoryDTO,
  DepartmentDTO,
  ECPDTO,
  EmployerDTO,
  ExerciseFormDTO,
  ExperienceDTO,
  MasterTitleDTO,
  PaymentTypeDTO,
  ProductBrandDTO,
  ProductCategoryDTO,
  ProductQuantityDTO,
  ProductWeightDTO,
  ProfessionalSkillDTO,
  ProfessionDTO,
  ProvinceDTO,
  QualificationDTO,
  RegionDTO,
  SpecializationDTO,
  TreatedPatologyDTO,
  UserCategoryDTO,
} from './../../commons/models/listing.model';

export const loadAll = createAction('[Listing] Load all');
export const loadAllCompleted = createAction('[Listing] Load all Completed');
export const loadAllFailed = createAction('[Listing] Load all Failed', props<{ error: string }>());

export const loadListingFailed = createAction('[Listing] Load listing Failed', props<{ entityType: string, error: string }>());
export const storeListingFailed = createAction('[Listing] Store listing Failed', props<{ entityType: string, error: string }>());

export const loadDepartments = createAction('[Listing] Load departments');
export const loadDepartmentsCompleted = createAction('[Listing] Load departments Completed', props<{ departments: DepartmentDTO[] }>());
export const storeDepartments = createAction('[Listing] Store departments', props<{ departments: DepartmentDTO[] }>());
export const storeDepartmentsCompleted = createAction('[Listing] Store departments Completed', props<{ departments: DepartmentDTO[] }>());

export const loadProfessions = createAction('[Listing] Load professions');
export const loadProfessionsCompleted = createAction('[Listing] Load professions Completed', props<{ professions: ProfessionDTO[] }>());
export const storeProfessions = createAction('[Listing] Store professions', props<{ professions: ProfessionDTO[] }>());
export const storeProfessionsCompleted = createAction('[Listing] Store professions Completed', props<{ professions: ProfessionDTO[] }>());

export const loadQualifications = createAction('[Listing] Load qualifications');
export const loadQualificationsCompleted = createAction('[Listing] Load qualifications Completed', props<{ qualifications: QualificationDTO[] }>());
export const storeQualifications = createAction('[Listing] Store qualifications', props<{ qualifications: QualificationDTO[] }>());
export const storeQualificationsCompleted = createAction('[Listing] Store qualifications Completed', props<{ qualifications: QualificationDTO[] }>());

export const loadUserCategories = createAction('[Listing] Load user categories');
export const loadUserCategoriesCompleted = createAction('[Listing] Load user categories Completed', props<{ user_categories: UserCategoryDTO[] }>());
export const storeUserCategories = createAction('[Listing] Store user categories', props<{ user_categories: UserCategoryDTO[] }>());
export const storeUserCategoriesCompleted = createAction('[Listing] Store user categories Completed', props<{ user_categories: UserCategoryDTO[] }>());

export const loadSpecializations = createAction('[Listing] Load specializations');
export const loadSpecializationsCompleted = createAction('[Listing] Load specializations Completed', props<{ specializations: SpecializationDTO[] }>());
export const storeSpecializations = createAction('[Listing] Store specializations', props<{ specializations: SpecializationDTO[] }>());
export const storeSpecializationsCompleted = createAction('[Listing] Store specializations Completed', props<{ specializations: SpecializationDTO[] }>());

export const loadRegions = createAction('[Listing] Load regions');
export const loadRegionsCompleted = createAction('[Listing] Load regions Completed', props<{ regions: RegionDTO[] }>());
export const storeRegions = createAction('[Listing] Store regions', props<{ regions: RegionDTO[] }>());
export const storeRegionsCompleted = createAction('[Listing] Store regions Completed', props<{ regions: RegionDTO[] }>());

export const loadProvinces = createAction('[Listing] Load provinces');
export const loadProvincesCompleted = createAction('[Listing] Load provinces Completed', props<{ provinces: ProvinceDTO[] }>());
export const storeProvinces = createAction('[Listing] Store provinces', props<{ provinces: ProvinceDTO[] }>());
export const storeProvincesCompleted = createAction('[Listing] Store provinces Completed', props<{ provinces: ProvinceDTO[] }>());

export const loadEmployers = createAction('[Listing] Load employers');
export const loadEmployersCompleted = createAction('[Listing] Load employers Completed', props<{ employers: EmployerDTO[] }>());
export const storePublicEmployers = createAction('[Listing] Store public employers', props<{ employers: EmployerDTO[] }>());
export const storePublicEmployersCompleted = createAction('[Listing] Store public employers Completed', props<{ employers: EmployerDTO[] }>());
export const storePrivateEmployers = createAction('[Listing] Store private employers', props<{ employers: EmployerDTO[] }>());
export const storePrivateEmployersCompleted = createAction('[Listing] Store private employers Completed', props<{ employers: EmployerDTO[] }>());

export const loadExerciseForms = createAction('[Listing] Load exercise forms');
export const loadExerciseFormsCompleted = createAction('[Listing] Load exercise forms Completed', props<{ exercise_forms: ExerciseFormDTO[] }>());
export const storeExerciseForms = createAction('[Listing] Store exercise forms', props<{ exercise_forms: ExerciseFormDTO[] }>());
export const storeExerciseFormsCompleted = createAction('[Listing] Store exercise forms Completed', props<{ exercise_forms: ExerciseFormDTO[] }>());

export const loadTreatedPatologies = createAction('[Listing] Load treated pathologies');
export const loadTreatedPatologiesCompleted = createAction('[Listing] Load treated pathologies Completed', props<{ treated_pathologies: TreatedPatologyDTO[] }>());
export const storeTreatedPatologies = createAction('[Listing] Store treated pathologies', props<{ treated_pathologies: TreatedPatologyDTO[] }>());
export const storeTreatedPatologiesCompleted = createAction('[Listing] Store treated pathologies Completed', props<{ treated_pathologies: TreatedPatologyDTO[] }>());

export const loadProfessionalSkills = createAction('[Listing] Load professional skills');
export const loadProfessionalSkillsCompleted = createAction('[Listing] Load professional skills Completed', props<{ professional_skills: ProfessionalSkillDTO[] }>());
export const storeProfessionalSkills = createAction('[Listing] Store professional skills', props<{ professional_skills: ProfessionalSkillDTO[] }>());
export const storeProfessionalSkillsCompleted = createAction('[Listing] Store professional skills Completed', props<{ professional_skills: ProfessionalSkillDTO[] }>());

export const loadExperiences = createAction('[Listing] Load experiences');
export const loadExperiencesCompleted = createAction('[Listing] Load experiences Completed', props<{ experiences: ExperienceDTO[] }>());
export const storeExperiences = createAction('[Listing] Store experiences', props<{ experiences: ExperienceDTO[] }>());
export const storeExperiencesCompleted = createAction('[Listing] Store experiences Completed', props<{ experiences: ExperienceDTO[] }>());

export const loadProductCategories = createAction('[Listing] Load product categories');
export const loadProductCategoriesCompleted = createAction('[Listing] Load product categories Completed', props<{ product_categories: ProductCategoryDTO[] }>());
export const storeProductCategories = createAction('[Listing] Store product categories', props<{ product_categories: ProductCategoryDTO[] }>());
export const storeProductCategoriesCompleted = createAction('[Listing] Store product categories Completed', props<{ product_categories: ProductCategoryDTO[] }>());

export const loadQuantitites = createAction('[Listing] Load quantities');
export const loadQuantititesCompleted = createAction('[Listing] Load quantities Completed', props<{ quantities: ProductQuantityDTO[] }>());
export const storeQuantitites = createAction('[Listing] Store quantities', props<{ quantities: ProductQuantityDTO[] }>());
export const storeQuantititesCompleted = createAction('[Listing] Store quantities Completed', props<{ quantities: ProductQuantityDTO[] }>());

export const loadWeights = createAction('[Listing] Load weights');
export const loadWeightsCompleted = createAction('[Listing] Load weights Completed', props<{ weights: ProductWeightDTO[] }>());
export const storeWeights = createAction('[Listing] Store weights', props<{ weights: ProductWeightDTO[] }>());
export const storeWeightsCompleted = createAction('[Listing] Store weights Completed', props<{ weights: ProductWeightDTO[] }>());

export const loadBrands = createAction('[Listing] Load brands');
export const loadBrandsCompleted = createAction('[Listing] Load brands Completed', props<{ brands: ProductBrandDTO[] }>());
export const storeBrands = createAction('[Listing] Store brands', props<{ brands: ProductBrandDTO[] }>());
export const storeBrandsCompleted = createAction('[Listing] Store brands Completed', props<{ brands: ProductBrandDTO[] }>());

export const loadMasterTitles = createAction('[Listing] Load master titles');
export const loadMasterTitlesCompleted = createAction('[Listing] Load master titles Completed', props<{ master_titles: MasterTitleDTO[] }>());
export const storeMasterTitles = createAction('[Listing] Store master titles', props<{ master_titles: MasterTitleDTO[] }>());
export const storeMasterTitlesCompleted = createAction('[Listing] Store master titles Completed', props<{ master_titles: MasterTitleDTO[] }>());

export const loadECPs = createAction('[Listing] Load ECPs');
export const loadECPsCompleted = createAction('[Listing] Load ECPs Completed', props<{ ecps: ECPDTO[] }>());
export const storeECPs = createAction('[Listing] Store ECPs', props<{ ecps: ECPDTO[] }>());
export const storeECPsCompleted = createAction('[Listing] Store ECPsCompleted', props<{ ecps: ECPDTO[] }>());

export const loadAgreements = createAction('[Listing] Load agreements');
export const loadAgreementsCompleted = createAction('[Listing] Load agreements Completed', props<{ agreements: AgreementDTO[] }>());
export const storeAgreements = createAction('[Listing] Store agreements', props<{ agreements: AgreementDTO[] }>());
export const storeAgreementsCompleted = createAction('[Listing] Store agreements Completed', props<{ agreements: AgreementDTO[] }>());

export const loadPaymentTypes = createAction('[Listing] Load payment types');
export const loadPaymentTypesCompleted = createAction('[Listing] Load payment types Completed', props<{ payment_types: PaymentTypeDTO[] }>());
export const storePaymentTypes = createAction('[Listing] Store payment types', props<{ payment_types: PaymentTypeDTO[] }>());
export const storePaymentTypesCompleted = createAction('[Listing] Store payment types Completed', props<{ payment_types: PaymentTypeDTO[] }>());

export const loadConventionCategories = createAction('[Listing] Load convention categories');
export const loadConventionCategoriesCompleted = createAction('[Listing] Load convention categories Completed', props<{ convention_categories: ConventionCategoryDTO[] }>());
export const storeConventionCategories = createAction('[Listing] Store convention categories', props<{ convention_categories: ConventionCategoryDTO[] }>());
export const storeConventionCategoriesCompleted = createAction('[Listing] Store convention categories Completed', props<{ convention_categories: ConventionCategoryDTO[] }>());

export const loadCertificateTypes = createAction('[Listing] Load certificate types');
export const loadCertificateTypesCompleted = createAction('[Listing] Load certificate types Completed', props<{ certificate_types: CertificateTypeDTO[] }>());
export const storeCertificateTypes = createAction('[Listing] Store certificate types', props<{ certificate_types: CertificateTypeDTO[] }>());
export const storeCertificateTypesCompleted = createAction('[Listing] Store certificate types Completed', props<{ certificate_types: CertificateTypeDTO[] }>());
