import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { environment } from 'src/environments/environment';

import { BaseDTO } from '../../models/base.model';
import {
  AgreementDTO,
  CertificateTypeDTO,
  ConventionCategoryDTO,
  DepartmentDTO,
  ECPDTO,
  EmployerDTO,
  MasterTitleDTO,
  PaymentTypeDTO,
  ProductBrandDTO,
  ProductCategoryDTO,
  ProductQuantityDTO,
  ProductWeightDTO,
  ProfessionDTO,
  ProvinceDTO,
  RegionDTO,
} from '../../models/listing.model';
import {
  ExerciseFormDTO,
  ExperienceDTO,
  ProfessionalSkillDTO,
  QualificationDTO,
  SpecializationDTO,
  TreatedPatologyDTO,
  UserCategoryDTO,
} from './../../models/listing.model';

abstract class LaravelResourceService<T extends BaseDTO> {

  private http: HttpClient;
  constructor(private _httpClient: HttpClient) {
    this.http = _httpClient;
  }

  abstract get entityName(): string;

  private get route(): string {
    return `${environment.baseUrl}/api/${this.entityName}`
  }

  public getById(id: number): Observable<T> {
    return this.http.get<T>(`${this.route}/${id}`);
  }

  public list(page?: number,
    per_page?: number,
    sort?: string,
    direction?: string): Observable<ListResultDTO<T>> {
    let params = {};
    if (sort) params["order"] = "" + sort;
    if (direction) params["direction"] = "" + direction;
    if (per_page) params["per_page"] = "" + per_page;
    if (page) params["page"] = "" + page;

    return this.http.get<ListResultDTO<T>>(this.route, {
      params: new HttpParams({ fromObject: params })
    });

  }

  public upsert(dto: T): Observable<T> {
    return dto.id ? this.update(dto) : this.create(dto);
  }

  public create(dto: T): Observable<T> {
    return this.http.post<T>(this.route, dto);
  }

  public update(dto: T): Observable<T> {
    return this.http.put<T>(`${this.route}/${dto.id}`, dto);
  }

  public delete(id): Observable<any> {
    return this.http.delete<any>(`${this.route}/${id}`);
  }

  public storeAll(list: T[], url: string = 'store_all'): Observable<T[]> {
    return this.http.post<T[]>(`${this.route}/${url}`, { list });
  }
}

@Injectable({ providedIn: "root" })
export class LaravelDepartmentService extends LaravelResourceService<DepartmentDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "departments";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelProfessionService extends LaravelResourceService<ProfessionDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "professions";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelEmployerService extends LaravelResourceService<EmployerDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "employers";
  }

  public storeAllPublic(dtos: EmployerDTO[]): Observable<EmployerDTO[]> {
    return this.storeAll(dtos, 'store_all/true');
  }

  public storeAllPrivate(dtos: EmployerDTO[]): Observable<EmployerDTO[]> {
    return this.storeAll(dtos, 'store_all/false');
  }
}

@Injectable({ providedIn: "root" })
export class LaravelQualificationService extends LaravelResourceService<QualificationDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "qualifications";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelRegionService extends LaravelResourceService<RegionDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "regions";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelProvinceService extends LaravelResourceService<ProvinceDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "provinces";
  }
}


@Injectable({ providedIn: "root" })
export class LaravelUserCategoryService extends LaravelResourceService<UserCategoryDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "usercategories";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelSpecializationService extends LaravelResourceService<SpecializationDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "specializations";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelExerciseFormService extends LaravelResourceService<ExerciseFormDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "exerciseforms";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelTreatedPathologyService extends LaravelResourceService<TreatedPatologyDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "treatedpathologies";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelProfessionalSkillService extends LaravelResourceService<ProfessionalSkillDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "professionalskills";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelExperienceService extends LaravelResourceService<ExperienceDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "experiences";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelProductBrandService extends LaravelResourceService<ProductBrandDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "productbrands";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelProductQuantityService extends LaravelResourceService<ProductQuantityDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "productquantities";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelProductCategoryService extends LaravelResourceService<ProductCategoryDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "productcategories";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelProductWeightService extends LaravelResourceService<ProductWeightDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "productweights";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelMasterTitleService extends LaravelResourceService<MasterTitleDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "mastertitles";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelECPService extends LaravelResourceService<ECPDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "ecps";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelAgreementService extends LaravelResourceService<AgreementDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "agreements";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelPaymentTypeService extends LaravelResourceService<PaymentTypeDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "paymenttypes";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelConventionCategoryService extends LaravelResourceService<ConventionCategoryDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "conventioncategories";
  }
}

@Injectable({ providedIn: "root" })
export class LaravelCertificateTypeService extends LaravelResourceService<CertificateTypeDTO> {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  get entityName(): string {
    return "certificatetypes";
  }
}
