import { Action, createReducer, on } from '@ngrx/store';

import * as BlockActions from '../actions/block.actions';
import { BlockDTO } from './../../commons/models/block.model';

export interface BlockState {
  blocks: BlockDTO[],
};

const initialState: BlockState = {
  blocks: null,
};

const blockReducer = createReducer(
  initialState,
  on(BlockActions.loadBlocksCompleted, (state, { blocks }) => {
    return { ...state, blocks };
  })
);

export function reducer(state: BlockState | undefined, action: Action) {
  return blockReducer(state, action);
}

