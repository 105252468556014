import { createSelector } from '@ngrx/store';

import { selectGlossaryState } from '../reducers';
import { GlossaryState } from '../reducers/glossary.reducer';

// Glossary Selectors

export const getGlossaryTermsTableState = createSelector(
  selectGlossaryState,
  (state: GlossaryState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters } }
);

export const getGlossaryTerms = createSelector(
  selectGlossaryState,
  (state: GlossaryState) => state.terms
);

export const getTotalGlossaryTerms = createSelector(
  getGlossaryTermsTableState,
  (tableState) => tableState.total
);

export const getGlossaryTermsCurrentPage = createSelector(
  getGlossaryTermsTableState,
  (tableState) => tableState.currentPage
);

export const getGlossaryTermsPerPage = createSelector(
  getGlossaryTermsTableState,
  (tableState) => tableState.perPage
);

export const getGlossaryTermsOrder = createSelector(
  getGlossaryTermsTableState,
  (tableState) => tableState.order
);

export const getGlossaryTermsDirection = createSelector(
  getGlossaryTermsTableState,
  (tableState) => tableState.direction
);

export const getGlossaryTermsFilters = createSelector(
  getGlossaryTermsTableState,
  (tableState) => tableState.filters
);

export const getGlossaryDialogId = createSelector(
  selectGlossaryState,
  (state: GlossaryState) => state.dialogId
);
