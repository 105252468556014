import { createAction, props } from '@ngrx/store';

import { GlossaryTerm, GlossaryTermDTO, GlossaryTermFilters } from '../../commons/models/glossary.model';

export const loadGlossary = createAction('[Glossary] Load glossary', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: GlossaryTermFilters }>());
export const loadGlossaryCompleted = createAction('[Glossary] Load glossary Completed', props<{ terms: GlossaryTermDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: GlossaryTermFilters }>());
export const loadGlossaryFailed = createAction('[Glossary] Load glossary Failed', props<{ error: any }>());

export const changePage = createAction('[Glossary] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Glossary] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Glossary] Change filters', props<{ filters: GlossaryTermFilters }>());

export const editGlossary = createAction('[Glossary] Edit glossary', props<{ glossary: GlossaryTerm }>());
export const glossaryDialogOpened = createAction('[Glossary] Detail dialog opened', props<{ dialogId: string }>());
export const closeGlossaryDialog = createAction('[Glossary] Close detail dialog');

export const saveGlossary = createAction('[Glossary] Save glossary', props<{ glossary: GlossaryTerm }>());
export const saveGlossaryCompleted = createAction('[Glossary] Save glossary completed', props<{ glossary: GlossaryTermDTO }>());
export const saveGlossaryFailed = createAction('[Glossary] Save glossary failed', props<{ error: any }>());

export const deleteGlossary = createAction('[Glossary] Delete glossary', props<{ glossary: GlossaryTerm }>());
export const deleteGlossaryCompleted = createAction('[Glossary] Delete glossary completed', props<{ glossary: GlossaryTermDTO }>());
export const deleteGlossaryCancelled = createAction('[Glossary] Delete glossary cancelled');
export const deleteGlossaryFailed = createAction('[Glossary] Delete glossary failed', props<{ error: any }>());
