import { Action, createReducer, on } from '@ngrx/store';

import {
  AgreementDTO,
  CertificateTypeDTO,
  ConventionCategoryDTO,
  DepartmentDTO,
  ECPDTO,
  EmployerDTO,
  ExerciseFormDTO,
  ExperienceDTO,
  MasterTitleDTO,
  PaymentTypeDTO,
  ProfessionalSkillDTO,
  ProfessionDTO,
  ProvinceDTO,
  QualificationDTO,
  RegionDTO,
  SpecializationDTO,
  TreatedPatologyDTO,
  UserCategoryDTO,
} from '../../commons/models/listing.model';
import * as ListingActions from '../actions/listing.actions';
import {
  ProductBrandDTO,
  ProductCategoryDTO,
  ProductQuantityDTO,
  ProductWeightDTO,
} from './../../commons/models/listing.model';

export interface ListingState {
  departments: DepartmentDTO[],
  professions: ProfessionDTO[],
  employers: EmployerDTO[],
  qualifications: QualificationDTO[],
  user_categories: UserCategoryDTO[],
  specializations: SpecializationDTO[],
  regions: RegionDTO[],
  provinces: ProvinceDTO[],
  exercise_forms: ExerciseFormDTO[],
  treated_pathologies: TreatedPatologyDTO[],
  professional_skills: ProfessionalSkillDTO[],
  experiences: ExperienceDTO[],
  brands: ProductBrandDTO[],
  product_categories: ProductCategoryDTO[],
  quantities: ProductQuantityDTO[],
  weights: ProductWeightDTO[],
  //Iscrizione Pegaso
  master_titles: MasterTitleDTO[],
  ecps: ECPDTO[],
  agreements: AgreementDTO[],
  payment_types: PaymentTypeDTO[],
  convention_categories: ConventionCategoryDTO[],
  //Certificati
  certificate_types: CertificateTypeDTO[]
};

const initialState: ListingState = {
  departments: null,
  professions: null,
  employers: null,
  qualifications: null,
  user_categories: null,
  specializations: null,
  regions: null,
  provinces: null,
  exercise_forms: null,
  treated_pathologies: null,
  professional_skills: null,
  experiences: null,
  brands: null,
  product_categories: null,
  quantities: null,
  weights: null,
  master_titles: null,
  ecps: null,
  agreements: null,
  payment_types: null,
  convention_categories: null,
  certificate_types: null
};

const listingReduce = createReducer(
  initialState,
  on(ListingActions.loadDepartmentsCompleted, (state, { departments }) => {
    return { ...state, departments };
  }),
  on(ListingActions.storeDepartmentsCompleted, (state, { departments }) => {
    return { ...state, departments };
  }),
  on(ListingActions.loadProfessionsCompleted, (state, { professions }) => {
    return { ...state, professions };
  }),
  on(ListingActions.storeProfessionsCompleted, (state, { professions }) => {
    return { ...state, professions };
  }),
  on(ListingActions.loadEmployersCompleted, (state, { employers }) => {
    return { ...state, employers };
  }),
  on(ListingActions.storePublicEmployersCompleted, (state, { employers }) => {
    let newArray = [...employers];
    if (state.employers) {
      newArray = [...state.employers.filter(e => !e.is_public), ...employers];
    }
    return { ...state, employers: newArray };
  }),
  on(ListingActions.storePrivateEmployersCompleted, (state, { employers }) => {
    let newArray = [...employers];
    if (state.employers) {
      newArray = [...state.employers.filter(e => e.is_public), ...employers];
    }
    return { ...state, employers: newArray };
  }),
  on(ListingActions.loadQualificationsCompleted, (state, { qualifications }) => {
    return { ...state, qualifications };
  }),
  on(ListingActions.storeQualificationsCompleted, (state, { qualifications }) => {
    return { ...state, qualifications };
  }),
  on(ListingActions.loadUserCategoriesCompleted, (state, { user_categories }) => {
    return { ...state, user_categories };
  }),
  on(ListingActions.storeUserCategoriesCompleted, (state, { user_categories }) => {
    return { ...state, user_categories };
  }),
  on(ListingActions.loadSpecializationsCompleted, (state, { specializations }) => {
    return { ...state, specializations };
  }),
  on(ListingActions.storeSpecializationsCompleted, (state, { specializations }) => {
    return { ...state, specializations };
  }),
  on(ListingActions.loadRegionsCompleted, (state, { regions }) => {
    return { ...state, regions };
  }),
  on(ListingActions.storeRegionsCompleted, (state, { regions }) => {
    return { ...state, regions };
  }),
  on(ListingActions.loadProvincesCompleted, (state, { provinces }) => {
    return { ...state, provinces };
  }),
  on(ListingActions.storeProvincesCompleted, (state, { provinces }) => {
    return { ...state, provinces };
  }),
  on(ListingActions.loadExerciseFormsCompleted, (state, { exercise_forms }) => {
    return { ...state, exercise_forms };
  }),
  on(ListingActions.storeExerciseFormsCompleted, (state, { exercise_forms }) => {
    return { ...state, exercise_forms };
  }),
  on(ListingActions.loadTreatedPatologiesCompleted, (state, { treated_pathologies }) => {
    return { ...state, treated_pathologies };
  }),
  on(ListingActions.storeTreatedPatologiesCompleted, (state, { treated_pathologies }) => {
    return { ...state, treated_pathologies };
  }),
  on(ListingActions.loadProfessionalSkillsCompleted, (state, { professional_skills }) => {
    return { ...state, professional_skills };
  }),
  on(ListingActions.storeProfessionalSkillsCompleted, (state, { professional_skills }) => {
    return { ...state, professional_skills };
  }),
  on(ListingActions.loadExperiencesCompleted, (state, { experiences }) => {
    return { ...state, experiences };
  }),
  on(ListingActions.storeExperiencesCompleted, (state, { experiences }) => {
    return { ...state, experiences };
  }),
  on(ListingActions.loadBrandsCompleted, (state, { brands }) => {
    return { ...state, brands };
  }),
  on(ListingActions.storeBrandsCompleted, (state, { brands }) => {
    return { ...state, brands };
  }),
  on(ListingActions.loadQuantititesCompleted, (state, { quantities }) => {
    return { ...state, quantities };
  }),
  on(ListingActions.storeQuantititesCompleted, (state, { quantities }) => {
    return { ...state, quantities };
  }),
  on(ListingActions.loadProductCategoriesCompleted, (state, { product_categories }) => {
    return { ...state, product_categories };
  }),
  on(ListingActions.storeProductCategoriesCompleted, (state, { product_categories }) => {
    return { ...state, product_categories };
  }),
  on(ListingActions.loadWeightsCompleted, (state, { weights }) => {
    return { ...state, weights };
  }),
  on(ListingActions.storeWeightsCompleted, (state, { weights }) => {
    return { ...state, weights };
  }),
  on(ListingActions.loadMasterTitlesCompleted, (state, { master_titles }) => {
    return { ...state, master_titles };
  }),
  on(ListingActions.storeMasterTitlesCompleted, (state, { master_titles }) => {
    return { ...state, master_titles };
  }),
  on(ListingActions.loadECPsCompleted, (state, { ecps }) => {
    return { ...state, ecps };
  }),
  on(ListingActions.storeECPsCompleted, (state, { ecps }) => {
    return { ...state, ecps };
  }),
  on(ListingActions.loadAgreementsCompleted, (state, { agreements }) => {
    return { ...state, agreements };
  }),
  on(ListingActions.storeAgreementsCompleted, (state, { agreements }) => {
    return { ...state, agreements };
  }),
  on(ListingActions.loadPaymentTypesCompleted, (state, { payment_types }) => {
    return { ...state, payment_types };
  }),
  on(ListingActions.storePaymentTypesCompleted, (state, { payment_types }) => {
    return { ...state, payment_types };
  }),
  on(ListingActions.loadConventionCategoriesCompleted, (state, { convention_categories }) => {
    return { ...state, convention_categories };
  }),
  on(ListingActions.storeConventionCategoriesCompleted, (state, { convention_categories }) => {
    return { ...state, convention_categories };
  }),
  on(ListingActions.loadCertificateTypesCompleted, (state, { certificate_types }) => {
    return { ...state, certificate_types };
  }),
  on(ListingActions.storeCertificateTypesCompleted, (state, { certificate_types }) => {
    return { ...state, certificate_types };
  }),
);

export function reducer(state: ListingState | undefined, action: Action) {
  return listingReduce(state, action);
}

