import { createAction, props } from '@ngrx/store';
import { BlockDTO } from 'src/app/commons/models/block.model';

export const loadBlocks = createAction('[Block] Load blocks');
export const loadBlocksCompleted = createAction('[Block] Load blocks Completed', props<{ blocks: BlockDTO[] }>());
export const loadBlocksFailed = createAction('[Block] Load blocks Failed', props<{ error: any }>());

export const saveAllBlocks = createAction('[Block] Save all blocks', props<{ blocks: BlockDTO[] }>());
export const saveAllBlocksCompleted = createAction('[Block] Save all blocks completed', props<{ blocks: BlockDTO[] }>());
export const saveAllBlocksFailed = createAction('[Block] Save all blocks failed', props<{ error: any }>());
