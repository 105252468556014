import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';

export interface RoleFilters {
  search?: string;
}

export interface RoleDTO extends BaseDTO {
  name?: string;
  permissions?: PermissionDTO[];
  permission_names?: string[]
}

export interface PermissionDTO extends BaseDTO {
  name: string;
}

export class Role extends Base {
  name?: string;
  permissions?: Permission[];

  constructor(dto: RoleDTO) {
    super(dto);
    if (dto) {
      this.name = dto.name;
      if (dto.permissions) {
        this.permissions = dto.permissions.map(p => new Permission(p));
      }
    }
  }

  toDTO(): RoleDTO {
    let dto: RoleDTO = <RoleDTO>super.toDTO();
    dto.name = this.name;
    dto.permissions = this.permissions ? this.permissions.map(p => p.toDTO()) : null;
    dto.permission_names = this.permissions ? this.permissions.map(p => p.name) : null;
    return dto;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Role) {
    const formModel = formGroup.value;
    let role: Role = new Role(null);
    role.name = formModel.name;
    role.permissions = formModel.permissions;
    if (original) {
      role.id = original.id;
    }
    return role;
  }

  get permissionNames(): string[] {
    return this.permissions ? this.permissions.map(p => p.name) : [];
  }
}

export class Permission extends Base {
  name: string
  constructor(dto: PermissionDTO) {
    super(dto);
    this.name = dto.name;
  }

  toDTO(): PermissionDTO {
    let dto: PermissionDTO = <PermissionDTO>super.toDTO();
    dto.name = this.name;
    return dto;
  }
}
