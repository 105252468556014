import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { User } from 'src/app/commons/models/user.model';
import { closeChangePasswordDialog, updatePassword } from 'src/app/store/actions/auth.actions';
import { AppState } from 'src/app/store/reducers';


@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {
  user: User;

  passwordForm: FormGroup;
  constructor(private store$: Store<AppState>, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data) {
      this.user = this.data.user;
    }

    this._createForm();
  }

  private _createForm() {

    let group = {
      password: ["", Validators.required],
      confirmPass: ["", Validators.required]
    }

    this.passwordForm = this.fb.group(group);
    this.passwordForm.setValidators([this.checkIfMatchingPasswords("password", "confirmPass")]);
  }

  save() {
    this.store$.dispatch(updatePassword({ newPassword: this.passwordForm.value["password"], user: this.user }));
  }

  close() {
    this.store$.dispatch(closeChangePasswordDialog());
  }

  private checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ): ValidatorFn {
    return (control: FormGroup): { [key: string]: boolean } | null => {
      let passwordInput = control.controls[passwordKey],
        passwordConfirmationInput = control.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return { notEquivalent: true };
      } else {
        return null;
      }
    };
  }
}
