import { createSelector } from '@ngrx/store';
import { User } from 'src/app/commons/models/user.model';

import { selectAuthState } from '../reducers';
import { AuthState } from '../reducers/auth.reducer';

export const getAuthToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.token
);

export const getCurrentUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.currentUser && state.token ? new User(state.currentUser) : null
);

export const getIsAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => state.currentUser && state.token ? true : false
);

export const getChangePasswordDialogId = createSelector(
  selectAuthState,
  (state: AuthState) => state.changePasswordDialogId
);
