import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { environment } from 'src/environments/environment';

import { GlossaryTermDTO, GlossaryTermFilters } from './../../models/glossary.model';


@Injectable({
  providedIn: "root"
})
export class LaravelGlossaryService {
  constructor(private httpClient: HttpClient) { }
  private get route(): string {
    return `${environment.baseUrl}/api/glossaryterms`;
  }

  public getById(id: number): Observable<GlossaryTermDTO> {
    return this.httpClient.get<GlossaryTermDTO>(`${this.route}/${id}`);
  }

  public list(page?: number,
    per_page?: number,
    sort?: string,
    direction?: string,
    filters?: GlossaryTermFilters
  ): Observable<ListResultDTO<GlossaryTermDTO>> {
    let params = {};
    if (sort) params["order"] = "" + sort;
    if (direction) params["direction"] = "" + direction;
    if (per_page) params["per_page"] = "" + per_page;
    if (page) params["page"] = "" + page;
    if (filters) {
      if (filters.word) params["search"] = filters.word;
    }

    return this.httpClient.get<ListResultDTO<GlossaryTermDTO>>(this.route, {
      params: new HttpParams({ fromObject: params })
    });

  }

  public upsert(dto: GlossaryTermDTO): Observable<GlossaryTermDTO> {
    return dto.id ? this.update(dto) : this.create(dto);
  }

  public create(dto: GlossaryTermDTO): Observable<GlossaryTermDTO> {
    return this.httpClient.post<GlossaryTermDTO>(this.route, dto);
  }

  public update(dto: GlossaryTermDTO): Observable<GlossaryTermDTO> {
    return this.httpClient.put<GlossaryTermDTO>(`${this.route}/${dto.id}`, dto);
  }

  public delete(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.route}/${id}`);
  }
}
