import { AbstractControl } from '@angular/forms';
import { Specialization } from 'src/app/commons/models/listing.model';
import { formatDateForBackend, isBefore, addMonths } from 'src/app/helpers/time.utils';
import { environment } from 'src/environments/environment';

import { Base, BaseDTO } from './base.model';
import {
  Agreement,
  AgreementDTO,
  Department,
  DepartmentDTO,
  ECP,
  ECPDTO,
  ExerciseForm,
  ExerciseFormDTO,
  Experience,
  ExperienceDTO,
  MasterTitle,
  MasterTitleDTO,
  PaymentType,
  PaymentTypeDTO,
  Profession,
  ProfessionalSkill,
  ProfessionalSkillDTO,
  ProfessionDTO,
  Qualification,
  QualificationDTO,
  Region,
  RegionDTO,
  SpecializationDTO,
  TreatedPatology,
  TreatedPatologyDTO,
  UserCategory,
  UserCategoryDTO,
} from './listing.model';

export type EmployerType = "privato" | "pubblico" | "libero professionista";
export interface UserDTO extends BaseDTO {
  email: string;
  name?: string;
  surname?: string;
  phone?: string;
  description?: string;
  visible_in_search?: boolean;
  newsletter_enabled?: boolean;
  partner_communications_enabled: boolean;
  experience_id?: number;
  employer_type: EmployerType;
  employer: string;
  experience?: ExperienceDTO; //Anni di esperienza
  exercise_form_id?: number;
  exercise_form?: ExerciseFormDTO; //Forma di esercizio
  professions?: ProfessionDTO[]; //Professione
  specializations?: SpecializationDTO[]; //Specializzazione
  department_id?: number;
  department?: DepartmentDTO; //Reparto
  qualifications?: QualificationDTO[]; //Titolo di studio
  user_categories?: UserCategoryDTO[]; //Categoria
  user_trainings?: UserTrainingDTO[]; //Formazione
  professional_skills?: UserSkillDTO[]; //Capacità professionali
  treated_pathologies?: TreatedPatologyDTO[]; //Patologie trattate
  working_experiences?: WorkingExperienceDTO[]; //Esperienza lavorativa
  regions?: RegionDTO[];
  user_roles: string[];
  image?: string;
  password?: string;
  can_change_password: boolean;
  approved: boolean;
  birthdate: string;
  is_proposed: boolean;
  proposed_description: string;
  proposed_image: string;
  proposed_visible_in_search: boolean;
  is_completed: boolean;
  notes?: string;
  certificates?: CertificateDTO[];
}

export class User extends Base {
  email?: string;
  name?: string;
  surname?: string;
  phone?: string;
  description?: string;
  proposedDescription?: string;
  visibleInSearch?: boolean;
  newsletterEnabled?: boolean;
  partnerCommunicationsEnabled: boolean;
  proposedVisibleInSearch?: boolean;
  employerType: EmployerType;
  employer: string;
  experience?: Experience;
  exerciseForm?: ExerciseForm;
  professions?: Profession[];
  specializations?: Specialization[];
  department?: Department;
  qualifications?: Qualification[];
  userCategories?: UserCategory[];
  userTrainings?: UserTraining[];
  professionalSkills?: UserSkill[];
  treatedPathologies?: TreatedPatology[];
  workingExperiences?: WorkingExperience[];
  regions?: Region[];
  image?: any;
  proposedImage?: any;
  password?: string;
  readonly canChangePassword: boolean;
  approved: boolean;
  userRoles: string[];
  birthdate: Date;
  isProposed: boolean;
  isCompleted: boolean;
  notes?: string;
  certificates?: Certificate[];

  constructor(source: UserDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.email = source.email;
      this.surname = source.surname;
      this.phone = source.phone;
      this.description = source.description;
      this.proposedDescription = source.proposed_description;
      this.visibleInSearch = source.visible_in_search;
      this.newsletterEnabled = source.newsletter_enabled;
      this.partnerCommunicationsEnabled = source.partner_communications_enabled;
      this.proposedVisibleInSearch = source.proposed_visible_in_search;
      this.employerType = source.employer_type;
      this.employer = source.employer;
      if (source.experience) {
        this.experience = new Experience(source.experience);
        this.addLoadedRelation("experience");
      }
      if (source.exercise_form) {
        this.exerciseForm = new ExerciseForm(source.exercise_form);
        this.addLoadedRelation("exercise_form");
      }
      if (source.professions) {
        this.professions = source.professions.map((dto) => new Profession(dto));
        this.addLoadedRelation("professions");
      }
      if (source.specializations) {
        this.specializations = source.specializations.map(
          (dto) => new Specialization(dto)
        );
        this.addLoadedRelation("specializations");
      }
      if (source.department) {
        this.department = new Department(source.department);
        this.addLoadedRelation("department");
      }
      if (source.regions) {
        this.regions = source.regions.map((dto) => new Region(dto));
        this.addLoadedRelation("regions");
      }
      if (source.user_categories) {
        this.userCategories = source.user_categories.map(
          (dto) => new UserCategory(dto)
        );
        this.addLoadedRelation("user_categories");
      }
      if (source.user_trainings) {
        this.userTrainings = source.user_trainings.map(
          (dto) => new UserTraining(dto)
        );
        this.addLoadedRelation("user_trainings");
      }
      if (source.professional_skills) {
        this.professionalSkills = source.professional_skills.map(
          (dto) => new UserSkill(dto)
        );
        this.addLoadedRelation("professional_skills");
      }
      if (source.treated_pathologies) {
        this.treatedPathologies = source.treated_pathologies.map(
          (dto) => new TreatedPatology(dto)
        );
        this.addLoadedRelation("treated_pathologies");
      }
      if (source.working_experiences) {
        this.workingExperiences = source.working_experiences.map(
          (dto) => new WorkingExperience(dto)
        );
        this.addLoadedRelation("working_experiences");
      }
      this.userRoles = source.user_roles;
      this.image = source.image;
      this.proposedImage = source.proposed_image;
      this.canChangePassword = source.can_change_password;
      this.approved = source.approved;
      this.birthdate = source.birthdate ? new Date(source.birthdate) : null;
      this.isProposed = source.is_proposed;
      this.isCompleted = source.is_completed;
      this.notes = source.notes;
      if (source.certificates) {
        this.certificates = source.certificates.map(
          (dto) => new Certificate(dto)
        );
        this.addLoadedRelation("certificates");
      }
    }
  }

  toDTO(): UserDTO {
    let result: UserDTO = <UserDTO>super.toDTO();
    result.name = this.name;
    result.email = this.email;
    result.surname = this.surname;
    result.phone = this.phone;
    result.description = this.description;
    result.proposed_description = this.proposedDescription;
    result.visible_in_search = this.visibleInSearch;
    result.newsletter_enabled = this.newsletterEnabled;
    result.partner_communications_enabled = this.partnerCommunicationsEnabled;
    result.proposed_visible_in_search = this.proposedVisibleInSearch;
    result.employer_type = this.employerType;
    result.employer = this.employer;
    result.experience_id = this.experience ? this.experience.id : null;
    result.experience = this.experience ? this.experience.toDTO() : null;
    result.exercise_form_id = this.exerciseForm ? this.exerciseForm.id : null;
    result.professions = this.professions
      ? this.professions.map((entity) => entity.toDTO())
      : null;
    result.specializations = this.specializations
      ? this.specializations.map((entity) => entity.toDTO())
      : null;
    result.department_id = this.department ? this.department.id : null;
    result.department = this.department ? this.department.toDTO() : null;
    result.qualifications = this.qualifications
      ? this.qualifications.map((entity) => entity.toDTO())
      : null;
    result.user_categories = this.userCategories
      ? this.userCategories.map((entity) => entity.toDTO())
      : null;
    result.regions = this.regions
      ? this.regions.map((entity) => entity.toDTO())
      : null;
    result.user_trainings = this.userTrainings
      ? this.userTrainings.map((entity) => entity.toDTO())
      : null;
    result.professional_skills = this.professionalSkills
      ? this.professionalSkills.map((entity) => entity.toDTO())
      : null;
    result.treated_pathologies = this.treatedPathologies
      ? this.treatedPathologies.map((entity) => entity.toDTO())
      : null;
    result.working_experiences = this.workingExperiences
      ? this.workingExperiences.map((entity) => entity.toDTO())
      : null;
    //result.image_url = this.imageUrl;
    result.user_roles = this.userRoles;
    result.password = this.password;
    result.can_change_password = this.canChangePassword;
    result.approved = this.approved;
    result.is_proposed = this.isProposed;
    result.birthdate = this.birthdate
      ? formatDateForBackend(this.birthdate, false)
      : null;
    result.is_completed = this.isCompleted;
    result.notes = this.notes;
    result.certificates = this.certificates
      ? this.certificates.map((entity) => entity.toDTO())
      : null;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: User) {
    const formModel = formGroup.value;
    let user: User = new User(null);
    user.userRoles = formModel.role ? [formModel.role] : [];
    user.name = formModel.name;
    user.surname = formModel.surname;
    user.email = formModel.email;
    user.phone = formModel.phone;
    user.description = formModel.description;
    user.proposedDescription = formModel.description;
    user.visibleInSearch = formModel.visibleInSearch;
    user.newsletterEnabled = formModel.newsletterEnabled;
    user.partnerCommunicationsEnabled = formModel.partnerCommunicationsEnabled;
    user.proposedVisibleInSearch = formModel.visibleInSearch;
    user.employerType = formModel.employerType;
    user.employer = formModel.employerDetails;
    user.experience = formModel.experience;
    user.exerciseForm = formModel.exerciseForm;
    user.professions = formModel.professions;
    user.specializations = formModel.specializations;
    user.department = formModel.department;
    user.qualifications = formModel.qualifications;
    user.userCategories = formModel.userCategories ? [formModel.userCategories] : null;
    user.regions = formModel.regions;
    user.treatedPathologies = formModel.treatedPathologies;
    user.birthdate = formModel.birthdate;
    user.notes = formModel.notes;
    // user.imageUrl = formModel.imageUrl;
    if (original) {
      user.id = original.id;
    } else {
      user.password = formModel.password;
    }
    return user;
  }

  get fullName(): string {
    return `${this.name} ${this.surname}`;
  }

  get imageUrl(): string {
    return this.image
      ? environment.baseUrl + "/storage/avatars/" + this.image
      : null;
  }

  get proposedImageUrl(): string {
    return this.proposedImage
      ? environment.baseUrl + "/storage/avatars/" + this.proposedImage
      : null;
  }

  get role(): string {
    return this.userRoles && this.userRoles.length ? this.userRoles[0] : null;
  }
}

export interface UserTrainingDTO extends BaseDTO {
  starting_year?: number;
  ending_year?: number;
  title?: string;
  description?: string;
  place?: string;
}

export class UserTraining extends Base {
  startingYear?: number;
  endingYear?: number;
  title?: string;
  description?: string;
  place?: string;

  constructor(dto: UserTrainingDTO) {
    super(dto);
    if (dto) {
      this.startingYear = dto.starting_year;
      this.endingYear = dto.ending_year;
      this.title = dto.title;
      this.description = dto.description;
      this.place = dto.place;
    }
  }

  toDTO(): UserTrainingDTO {
    let result: UserTrainingDTO = <UserTrainingDTO>super.toDTO();
    result.starting_year = this.startingYear;
    result.ending_year = this.endingYear;
    result.title = this.title;
    result.description = this.description;
    result.place = this.place;

    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: UserTraining) {
    const formModel = formGroup.value;
    let userTraining: UserTraining = new UserTraining(null);
    userTraining.id = formModel.id;
    userTraining.startingYear = formModel.startingYear;
    userTraining.endingYear = formModel.endingYear;
    userTraining.title = formModel.title;
    userTraining.description = formModel.description;
    userTraining.place = formModel.place;
    if (original) {
      userTraining.id = original.id;
    }
    return userTraining;
  }
}

export interface WorkingExperienceDTO extends BaseDTO {
  start_date?: string;
  end_date?: string;
  description?: string;
  employer?: string;
}

export class WorkingExperience extends Base {
  start: Date;
  end: Date;
  description?: string;
  employer?: string;

  constructor(dto: WorkingExperienceDTO) {
    super(dto);
    if (dto) {
      if (dto.start_date) {
        this.start = new Date(dto.start_date);
      }
      if (dto.end_date) {
        this.end = new Date(dto.end_date);
      }
      this.description = dto.description;
      this.employer = dto.employer;
    }
  }

  toDTO(): UserTrainingDTO {
    let result: WorkingExperienceDTO = <WorkingExperienceDTO>super.toDTO();
    result.start_date = this.start ? formatDateForBackend(this.start) : null;
    result.end_date = this.end ? formatDateForBackend(this.end) : null;
    result.description = this.description;
    result.employer = this.employer;

    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: WorkingExperience
  ) {
    const formModel = formGroup.value;
    let workingExperience: WorkingExperience = new WorkingExperience(null);
    workingExperience.id = formModel.id;
    workingExperience.start = formModel.start;
    workingExperience.end = formModel.end;
    workingExperience.description = formModel.description;
    workingExperience.employer = formModel.employer;
    if (original) {
      workingExperience.id = original.id;
    }
    return workingExperience;
  }
}

export interface UserSkillDTO extends ProfessionalSkillDTO {
  pivot: {
    level: number;
  };
}

export class UserSkill extends ProfessionalSkill {
  level: number;

  constructor(dto: UserSkillDTO) {
    super(dto);
    if (dto) {
      if (dto.pivot) {
        this.level = dto.pivot.level;
      }
    }
  }

  toDTO(): UserSkillDTO {
    let result: UserSkillDTO = <UserSkillDTO>super.toDTO();
    result.pivot = { level: this.level };
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl) {
    const formModel = formGroup.value;
    let userSkill: UserSkill = new UserSkill(formModel.skill);
    userSkill.level = formModel.level;
    return userSkill;
  }
}

export interface UserFilters {
  approved?: "true" | "false" | "isProposed";
  visible?: boolean;
  newsletter_enabled?: boolean;
  partner_communications_enabled?: boolean;
  search?: string;
  profession_id?: Profession;
  specialization_id?: Specialization;
}

export interface RegistrationDTO extends BaseDTO {
  user?: UserDTO;
  master_title: MasterTitleDTO;
  ecp: ECPDTO;
  agreement: AgreementDTO;
  registration_date: Date;
  payment_date: Date;
  payment_type: PaymentTypeDTO;
  stamp_paid: boolean;
  paid: boolean;
  amount: number;
  identifier: string;
  name: string;
  surname: string;
  birth_date: Date;
  birth_nation: string;
  birth_province: string;
  birth_city: string;
  address: string;
  province: string;
  city: string;
  cap: string;
  phone: string;
  mobile: string;
  email: string;
  autocertificazione_filename: string;
  documento_riconoscimento_filename: string;
  documento_cf_filename: string;
  copia_bonifico_filename: string;
}
export class Registration extends Base {
  user?: User;
  masterTitle: MasterTitle;
  ecp: ECP;
  agreement: Agreement;
  registrationDate: Date;
  paymentDate: Date;
  paymentType: PaymentType;
  stampPaid: boolean;
  paid: boolean;
  amount: number;
  identifier: string;
  name: string;
  surname: string;
  birthDate: Date;
  birthNation: string;
  birthProvince: string;
  birthCity: string;
  address: string;
  province: string;
  city: string;
  CAP: string;
  phone: string;
  mobile: string;
  email: string;
  autocertificazioneFilename: string;
  documentoRiconoscimentoFilename: string;
  documentoCfFilename: string;
  copiaBonificoFilename: string;

  constructor(dto: RegistrationDTO) {
    super(dto);
    if (dto) {
      if (dto.user) {
        this.user = new User(dto.user);
      }
      if (dto.master_title) {
        this.masterTitle = new MasterTitle(dto.master_title);
        this.addLoadedRelation("master_title");
      }
      if (dto.ecp) {
        this.ecp = new ECP(dto.ecp);
        this.addLoadedRelation("ecp");
      }
      if (dto.agreement) {
        this.agreement = new Agreement(dto.agreement);
        this.addLoadedRelation("agreement");
      }
      if (dto.payment_type) {
        this.paymentType = new PaymentType(dto.payment_type);
        this.addLoadedRelation("payment_type");
      }
      this.registrationDate = dto.registration_date
        ? new Date(dto.registration_date)
        : null;
      this.paymentDate = dto.payment_date ? new Date(dto.payment_date) : null;
      this.stampPaid = dto.stamp_paid;
      this.paid = dto.paid;
      this.identifier = dto.identifier;
      this.name = dto.name;
      this.surname = dto.surname;
      this.birthDate = dto.birth_date ? new Date(dto.birth_date) : null;
      this.birthNation = dto.birth_nation;
      this.birthProvince = dto.birth_province;
      this.birthCity = dto.birth_city;
      this.address = dto.address;
      this.province = dto.province;
      this.city = dto.city;
      this.CAP = dto.cap;
      this.phone = dto.phone;
      this.mobile = dto.mobile;
      this.email = dto.email;
      this.amount = dto.amount;
      this.autocertificazioneFilename = dto.autocertificazione_filename;
      this.documentoRiconoscimentoFilename =
        dto.documento_riconoscimento_filename;
      this.documentoCfFilename = dto.documento_cf_filename;
      this.copiaBonificoFilename = dto.copia_bonifico_filename;
    }
  }

  toDTO(): RegistrationDTO {
    let result: RegistrationDTO = <RegistrationDTO>super.toDTO();
    result.master_title = this.masterTitle ? this.masterTitle.toDTO() : null;
    result.ecp = this.ecp ? this.ecp.toDTO() : null;
    result.agreement = this.agreement ? this.agreement.toDTO() : null;
    result.payment_type = this.paymentType ? this.paymentType.toDTO() : null;
    result.registration_date = this.registrationDate;
    result.payment_date = this.paymentDate;
    result.stamp_paid = this.stampPaid;
    result.paid = this.paid;
    result.identifier = this.identifier;
    result.name = this.name;
    result.surname = this.surname;
    result.birth_date = this.birthDate;
    result.birth_nation = this.birthNation;
    result.birth_province = this.birthProvince;
    result.birth_city = this.birthCity;
    result.address = this.address;
    result.province = this.province;
    result.city = this.city;
    result.cap = this.CAP;
    result.phone = this.phone;
    result.mobile = this.mobile;
    result.email = this.email;
    result.amount = this.amount;
    result.autocertificazione_filename = this.autocertificazioneFilename;
    result.documento_riconoscimento_filename = this.documentoRiconoscimentoFilename;
    result.documento_cf_filename = this.documentoCfFilename;
    result.copia_bonifico_filename = this.copiaBonificoFilename;
    if (this.user) {
      result.user = this.user.toDTO();
    }
    return result;
  }

  get place(): string {
    return `${this.address}, ${this.CAP} ${this.city} (${this.province})`;
  }

  get birthPlace(): string {
    return `${this.birthCity}, ${this.birthProvince} - ${this.birthNation}`;
  }
}

export interface RegistrationFilters {
  from?: Date;
  to?: Date;
}

export interface CertificateDTO extends BaseDTO {
  certificate_type: string; //Ad inserimento libero dell'utente
  description: string;
  release_date: string;
  expiration_date: string;
  user_id: number;
  user: UserDTO;
}

export class Certificate extends Base {
  certificateType: string; //Ad inserimento libero dell'utente
  description: string;
  releaseDate: Date;
  expirationDate: Date;
  userId: number;
  user: User;

  constructor(dto: CertificateDTO) {
    super(dto);
    if (dto) {
      this.certificateType = dto.certificate_type;
      this.description = dto.description;
      if (dto.release_date) {
        this.releaseDate = new Date(dto.release_date);
      }
      if (dto.expiration_date) {
        this.expirationDate = new Date(dto.expiration_date);
      }
      this.userId = dto.user_id;
      if (dto.user) {
        this.user = new User(dto.user);
        this.addLoadedRelation("user");
      }
    }
  }

  toDTO(): CertificateDTO {
    let result: CertificateDTO = <CertificateDTO>super.toDTO();
    result.certificate_type = this.certificateType;
    result.description = this.description;
    result.release_date = this.releaseDate ? formatDateForBackend(this.releaseDate) : null;
    result.expiration_date = this.expirationDate ? formatDateForBackend(this.expirationDate) : null;
    result.user_id = this.user ? this.user.id : this.userId;
    result.user = this.user ? this.user.toDTO() : null;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl): Certificate {
    const formModel = formGroup.value;
    let certificate: Certificate = new Certificate(null);
    certificate.certificateType = formModel.certificateType;
    certificate.description = formModel.description;
    certificate.releaseDate = formModel.releaseDate;
    certificate.expirationDate = formModel.expirationDate;
    //TODO mrosetti - Gestire "status"
    if (formModel.original) {
      certificate.id = formModel.original.id;
    }
    return certificate;
  }

  /**
   * Mimic backend logic;:
   *  if(!$this->expiration_date) return 'gray';
        $today = Carbon::today();
        if(Carbon::parse($this->expiration_date)->lessThanOrEqualTo($today)) {
            return 'red';
        }
        if(Carbon::parse($this->expiration_date)->lessThanOrEqualTo($today->addMonths(1))) {
            return 'yellow';
        }

        return 'green';
   */
  get status(): string {
    return Certificate.status(this.expirationDate);
  }

  static status(expirationDate: Date): string {
    if (expirationDate) {
      if (isBefore(expirationDate, new Date)) {
        return "red";
      }
      if (isBefore(expirationDate, addMonths(new Date, 1))) {
        return "yellow";
      }
      return "green";
    }
    return "grey";
  }
}
