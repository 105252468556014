import { createAction, props } from '@ngrx/store';

import { Convention, ConventionDTO, ConventionFilters } from '../../commons/models/convention.model';

export const loadConventions = createAction('[Convention] Load conventions', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: ConventionFilters }>());
export const loadConventionsCompleted = createAction('[Convention] Load conventions Completed', props<{ conventions: ConventionDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ConventionFilters }>());
export const loadConventionsFailed = createAction('[Convention] Load conventions Failed', props<{ error: any }>());

export const changePage = createAction('[Convention] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Convention] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Convention] Change filters', props<{ filters: ConventionFilters }>());

export const editConvention = createAction('[Convention] Edit convention', props<{ convention: Convention }>());
export const conventionDialogOpened = createAction('[Convention] Detail dialog opened', props<{ dialogId: string }>());
export const closeConventionDialog = createAction('[Convention] Close detail dialog');

export const saveConvention = createAction('[Convention] Save convention', props<{ convention: Convention }>());
export const saveConventionCompleted = createAction('[Convention] Save convention completed', props<{ convention: ConventionDTO }>());
export const saveConventionFailed = createAction('[Convention] Save convention failed', props<{ error: any }>());

export const deleteConvention = createAction('[Convention] Delete convention', props<{ convention: Convention }>());
export const deleteConventionCompleted = createAction('[Convention] Delete convention completed', props<{ convention: ConventionDTO }>());
export const deleteConventionCancelled = createAction('[Convention] Delete convention cancelled');
export const deleteConventionFailed = createAction('[Convention] Delete convention failed', props<{ error: any }>());
