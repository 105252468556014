<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="glossary">Modifica voce: {{glossary.word}}</span>
    <span *ngIf="!glossary">Nuova voce di glossario</span>
  </span>
  <span *ngIf="glossary">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteGlossary()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="glossaryForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field>
        <input matInput placeholder="Parola" formControlName="word" type="text" required>
        <mat-error>Obbligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field>
        <textarea matInput placeholder="Definizione" formControlName="description" type="description"></textarea>
        <mat-error>Obbligatorio</mat-error>
      </mat-form-field>
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="glossaryForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="glossaryForm.pristine || !glossaryForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
