import { Action, createReducer, on } from '@ngrx/store';

import { RoleDTO } from '../../commons/models/role.model';
import * as RoleActions from '../actions/role.actions';
import { PermissionDTO } from './../../commons/models/role.model';

export interface RoleState {
  list: RoleDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  dialogId: string,
  permissions: PermissionDTO[]
};

const initialState: RoleState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  dialogId: null,
  permissions: null
};

const roleReducer = createReducer(
  initialState,
  on(RoleActions.loadRolesCompleted, (state, { roles, currentPage, total, perPage, order, direction }) => {
    return { ...state, list: roles, currentPage, total, perPage, order, direction };
  }),
  on(RoleActions.roleDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(RoleActions.loadPermissionsCompleted, (state, { permissions }) => {
    return { ...state, permissions };
  }),
);

export function reducer(state: RoleState | undefined, action: Action) {
  return roleReducer(state, action);
}
