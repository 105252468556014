import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AlertService } from '../../commons/services/alert.service';
import { LaravelRoleService } from '../../commons/services/backend/laravel-role.service';
import { RoleEditComponent } from '../../modules/home/roles/role-edit/role-edit.component';
import * as RoleActions from '../actions/role.actions';
import { AppState } from '../reducers';
import { getRoleDialogId, getRolesTableState } from '../selectors/role.selectors';

@Injectable()
export class RoleEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.saveRoleFailed, RoleActions.deleteRoleFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), { dispatch: false }
  );

  loadRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.loadRoles),
      switchMap(({ page, perPage, order, direction }) => {
        return this.roleService.list(page, perPage, order, direction,)
          .pipe(
            map(result =>
              RoleActions.loadRolesCompleted({ roles: result.data, currentPage: page, total: result.total, perPage, order, direction })
            ),
            catchError(error => {
              return of(RoleActions.loadRolesFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.changePage),
      withLatestFrom(this.store$.select(getRolesTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order }]) => RoleActions.loadRoles({ page: page, perPage: size, order, direction }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.changeSort),
      withLatestFrom(this.store$.select(getRolesTableState)),
      map(([action, { total, currentPage, perPage, direction, order }]) => RoleActions.loadRoles({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction }))
    )
  );

  editRole$ = createEffect(() => this.actions$.pipe(
    ofType(RoleActions.editRole),
    map(({ role }) => {
      let dialogRef = this.dialog.open(RoleEditComponent, {
        width: "85%",
        data: {
          role
        }
      });
      return RoleActions.roleDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  saveRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.saveRole),
      mergeMap(({ role }) =>
        this.roleService.upsert(role.toDTO())
          .pipe(
            map(result =>
              RoleActions.saveRoleCompleted({ role: result })
            ),
            catchError(error => of(RoleActions.saveRoleFailed({ error })))
          )
      )
    )
  );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.saveRoleCompleted),
      map(action => action.role),
      tap(role => this.alertService.showConfirmMessage(`Ruolo ${role.name} salvato con successo`)),
      map(() => RoleActions.closeRoleDialog())
    )
  );

  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.closeRoleDialog),
      withLatestFrom(this.store$.select(getRoleDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.saveRoleCompleted, RoleActions.deleteRoleCompleted),
      withLatestFrom(this.store$.select(getRolesTableState)),
      map(([_, { total, currentPage, perPage, direction, order }]) => RoleActions.loadRoles({ page: currentPage, perPage, order, direction }))
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.deleteRole),
      map(action => action.role),
      mergeMap((role) =>
        this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare il ruolo ${role.name}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.roleService.delete(role.id)
                  .pipe(
                    map(() => RoleActions.deleteRoleCompleted({ role: role.toDTO() })),
                    catchError(error => of(RoleActions.deleteRoleFailed({ error })))
                  )
                : of(RoleActions.deleteRoleCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.deleteRoleCompleted),
      map(action => action.role),
      tap(role => this.alertService.showConfirmMessage(`Role ${role.name} eliminato con successo`)),
      map(() => RoleActions.closeRoleDialog())
    )
  );

  loadPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RoleActions.loadPermissions),
      switchMap(() => {
        return this.roleService.permissions()
          .pipe(
            map(permissions =>
              RoleActions.loadPermissionsCompleted({ permissions })
            ),
            catchError(error => {
              return of(RoleActions.loadPermissionsFailed({ error }))
            })
          )
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private roleService: LaravelRoleService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}
