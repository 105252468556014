import { createAction, props } from '@ngrx/store';

import { Exchange, ExchangeDTO, ExchangeFilters } from '../../commons/models/exchange.model';

export const loadExchanges = createAction('[Exchange] Load exchanges', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: ExchangeFilters }>());
export const loadExchangesCompleted = createAction('[Exchange] Load exchanges Completed', props<{ exchanges: ExchangeDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ExchangeFilters }>());
export const loadExchangesFailed = createAction('[Exchange] Load exchanges Failed', props<{ error: any }>());

export const changePage = createAction('[Exchange] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Exchange] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Exchange] Change filters', props<{ filters: ExchangeFilters }>());

export const editExchange = createAction('[Exchange] Edit exchange', props<{ exchange: Exchange }>());
export const exchangeDialogOpened = createAction('[Exchange] Detail dialog opened', props<{ dialogId: string }>());
export const closeExchangeDialog = createAction('[Exchange] Close detail dialog');

export const saveExchange = createAction('[Exchange] Save exchange', props<{ exchange: Exchange }>());
export const saveExchangeCompleted = createAction('[Exchange] Save exchange completed', props<{ exchange: ExchangeDTO }>());
export const saveExchangeFailed = createAction('[Exchange] Save exchange failed', props<{ error: any }>());

export const approveExchange = createAction('[Exchanges] Approve exchange', props<{ exchange: ExchangeDTO }>());
export const approveExchangeCompleted = createAction('[Exchanges] Approve exchange completed', props<{ exchange: ExchangeDTO }>());
export const approveExchangeCancelled = createAction('[Exchanges] Approve exchange cancelled');
export const approveExchangeFailed = createAction('[Exchanges] Approve exchange failed', props<{ error: any }>());

export const disapproveExchange = createAction('[Exchanges] Disapprove exchange', props<{ exchange: ExchangeDTO }>());
export const disapproveExchangeCompleted = createAction('[Exchanges] Disapprove exchange completed', props<{ exchange: ExchangeDTO }>());
export const disapproveExchangeCancelled = createAction('[Exchanges] DisaApprove exchange cancelled');
export const disapproveExchangeFailed = createAction('[Exchanges] Disaapprove exchange failed', props<{ error: any }>());

export const deleteExchange = createAction('[Exchange] Delete exchange', props<{ exchange: Exchange }>());
export const deleteExchangeCompleted = createAction('[Exchange] Delete exchange completed', props<{ exchange: ExchangeDTO }>());
export const deleteExchangeCancelled = createAction('[Exchange] Delete exchange cancelled');
export const deleteExchangeFailed = createAction('[Exchange] Delete exchange failed', props<{ error: any }>());

export const exportFeedback = createAction('[Feedback] Export feedback');
export const exportFeedbackCompleted = createAction('[Feedback] Export feedback completed', props<{ file: Blob }>());
export const exportFeedbackFailed = createAction('[Feedback] Export feedback failed', props<{ error: any }>());