import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';

export interface GlossaryTermFilters {
  word?: string
}
export interface GlossaryTermDTO extends BaseDTO {
  word?: string;
  description?: string;
}

export class GlossaryTerm extends Base {
  word?: string;
  description?: string;

  constructor(dto: GlossaryTermDTO) {
    super(dto);
    if (dto) {
      this.word = dto.word;
      this.description = dto.description;
    }
  }

  toDTO(): GlossaryTermDTO {
    let dto: GlossaryTermDTO = <GlossaryTermDTO>super.toDTO();
    dto.word = this.word;
    dto.description = this.description;
    return dto;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: GlossaryTerm) {
    const formModel = formGroup.value;
    let entity: GlossaryTerm = new GlossaryTerm(null);
    entity.word = formModel.word;
    entity.description = formModel.description;
    if (original) {
      entity.id = original.id;
    }
    return entity;
  }
}
