import { Action, createReducer, on } from '@ngrx/store';

import { RegistrationDTO, RegistrationFilters } from '../../commons/models/user.model';
import * as RegistrationActions from '../actions/registration.actions';

export interface RegistrationState {
  list: RegistrationDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: RegistrationFilters,
  dialogId: string
};

const initialState: RegistrationState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null
};

const registrationReducer = createReducer(
  initialState,
  on(RegistrationActions.loadRegistrationsCompleted, (state, { registrations, currentPage, total, perPage, order, direction, filters }) => {
    return { ...state, list: registrations, currentPage, total, perPage, order, direction, filters };
  }),
  on(RegistrationActions.registrationDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(RegistrationActions.changeFilters, (state, { filters }) => {
    return { ...state, filters };
  })
);

export function reducer(state: RegistrationState | undefined, action: Action) {
  return registrationReducer(state, action);
}

