import { createAction, props } from '@ngrx/store';

import { User, UserDTO } from '../../commons/models/user.model';

export const login = createAction('[Auth] Login', props<{ username: string, password: string }>());
export const loginCompleted = createAction('[Auth] Login Completed', props<{ token: string, currentUser: UserDTO }>());
export const loginFailed = createAction('[Auth] Login Failed', props<{ error: any }>());

export const logout = createAction('[Auth] Logout', props<{ showConfirm: boolean }>());
export const logoutCompleted = createAction('[Auth] Logout Completed', props<{ silent: boolean }>());
export const logoutCancelled = createAction('[Auth] Logout Cancelled');

export const loadCurrentUser = createAction('[Auth] Load current user');
export const loadCurrentUserCompleted = createAction('[Auth] Load current user completed', props<{ currentUser: UserDTO }>());
export const loadCurrentUserFailed = createAction('[Auth] Load current user failed', props<{ error: any }>());

export const changeUserPassword = createAction('[Users] Change user password', props<{ user?: User }>());
export const changePasswordDialogOpen = createAction('[Users] Change password dialog opened', props<{ dialogId: string }>());
export const closeChangePasswordDialog = createAction('[Users] Close change password dialog');

export const updatePassword = createAction('[Users] Update password', props<{ newPassword: string, user?: User }>());
export const updatePasswordCompleted = createAction('[Users] Update password completed', props<{ user: UserDTO }>());
export const updatePasswordFailed = createAction('[Users] Update password failed', props<{ error: any }>());
