import { Action, createReducer, on } from '@ngrx/store';

import { ConventionDTO } from '../../commons/models/convention.model';
import * as ConventionActions from '../actions/convention.actions';
import { ConventionFilters } from './../../commons/models/convention.model';

export interface ConventionState {
  list: ConventionDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  dialogId: string,
  filters: ConventionFilters
};

const initialState: ConventionState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  dialogId: null,
  filters: null
};

const conventionReducer = createReducer(
  initialState,
  on(ConventionActions.loadConventionsCompleted, (state, { conventions, currentPage, total, perPage, order, direction, filters }) => {
    return { ...state, list: conventions, currentPage, total, perPage, order, direction, filters };
  }),
  on(ConventionActions.conventionDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ConventionActions.changeFilters, (state, { filters }) => {
    return { ...state, filters };
  })
);

export function reducer(state: ConventionState | undefined, action: Action) {
  return conventionReducer(state, action);
}

