import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as GlossaryActions from 'src/app/store/actions/glossary.actions';
import { AppState } from 'src/app/store/reducers';

import { GlossaryTerm } from './../../../../commons/models/glossary.model';


@Component({
  selector: 'app-glossary-edit',
  templateUrl: './glossary-edit.component.html',
  styleUrls: ['./glossary-edit.component.scss']
})
export class GlossaryEditComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private _glossary: GlossaryTerm;

  glossaryForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this._createForm();
  }

  ngOnInit() {
    if (this.data) {
      this.glossary = this.data.glossary;
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      word: ["", Validators.required],
      description: ["", Validators.required],
    }

    this.glossaryForm = this.fb.group(group);
  }


  private ngOnChanges() {
    if (this.glossaryForm) {
      this.glossaryForm.reset();
      if (this.glossary) {
        this._initFormValues(this.glossary);
      }
    }
  }

  private _initFormValues(glossary: GlossaryTerm) {
    if (glossary) {
      this.glossaryForm.patchValue({
        word: glossary.word,
        description: glossary.description,

      });
    }
  }

  get glossary(): GlossaryTerm {
    return this._glossary;
  }

  set glossary(glossary: GlossaryTerm) {
    this._glossary = glossary;
    this.ngOnChanges();
  }

  private _prepareSave(): GlossaryTerm {
    let savingEntity: GlossaryTerm = GlossaryTerm.fromFormGroup(this.glossaryForm, this.glossary);
    return savingEntity;
  }

  save() {
    let unsavedEntity = this._prepareSave();
    this.store$.dispatch(GlossaryActions.saveGlossary({ glossary: unsavedEntity }));
  }

  deleteGlossary() {
    this.store$.dispatch(GlossaryActions.deleteGlossary({ glossary: this.glossary }));
  }

  close() {
    if (this.glossaryForm.pristine) {
      this.store$.dispatch(GlossaryActions.closeGlossaryDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(GlossaryActions.closeGlossaryDialog())
          }
        });
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
