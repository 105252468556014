import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { environment } from 'src/environments/environment';

import { BlockDTO } from '../../models/block.model';
import { PermissionDTO, RoleDTO } from '../../models/role.model';


@Injectable({
  providedIn: "root"
})
export class LaravelRoleService {
  constructor(private httpClient: HttpClient) { }

  private get rolesRoute(): string {
    return `${environment.baseUrl}/api/roles`;
  }

  private get permissionsRoute(): string {
    return `${environment.baseUrl}/api/permissions`;
  }

  public getById(id: number): Observable<BlockDTO> {
    return this.httpClient.get<BlockDTO>(`${this.rolesRoute}/${id}`);
  }

  public list(page?: number,
    per_page?: number,
    sort?: string,
    direction?: string,
  ): Observable<ListResultDTO<RoleDTO>> {
    let params = {};
    if (sort) params["sort"] = "" + sort;
    if (direction) params["direction"] = "" + direction;
    if (per_page) params["per_page"] = "" + per_page;
    if (page) params["page"] = "" + page;

    return this.httpClient.get<ListResultDTO<BlockDTO>>(this.rolesRoute, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public upsert(dto: RoleDTO): Observable<RoleDTO> {
    return dto.id ? this.update(dto) : this.create(dto);
  }

  public create(dto: RoleDTO): Observable<RoleDTO> {
    return this.httpClient.post<RoleDTO>(this.rolesRoute, dto);
  }

  public update(dto: RoleDTO): Observable<RoleDTO> {
    return this.httpClient.put<RoleDTO>(`${this.rolesRoute}/${dto.id}`, dto);
  }

  public delete(id): Observable<any> {
    return this.httpClient.delete<any>(`${this.rolesRoute}/${id}`);
  }

  public permissions(): Observable<PermissionDTO[]> {
    return this.httpClient.get<PermissionDTO[]>(this.permissionsRoute, {});
  }
}
