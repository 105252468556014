import { Action, createReducer, on } from '@ngrx/store';

import { ExchangeDTO } from '../../commons/models/exchange.model';
import * as ExchangeActions from '../actions/exchange.actions';
import { ExchangeFilters } from './../../commons/models/exchange.model';

export interface ExchangeState {
  list: ExchangeDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  dialogId: string,
  filters: ExchangeFilters
};

const initialState: ExchangeState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  dialogId: null,
  filters: null
};

const exchangeReducer = createReducer(
  initialState,
  on(ExchangeActions.loadExchangesCompleted, (state, { exchanges, currentPage, total, perPage, order, direction, filters }) => {
    return { ...state, list: exchanges, currentPage, total, perPage, order, direction, filters };
  }),
  on(ExchangeActions.exchangeDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ExchangeActions.changeFilters, (state, { filters }) => {
    return { ...state, filters };
  })
);

export function reducer(state: ExchangeState | undefined, action: Action) {
  return exchangeReducer(state, action);
}

