import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AlertService } from '../../commons/services/alert.service';
import * as ListingActions from '../actions/listing.actions';
import {
  LaravelAgreementService,
  LaravelCertificateTypeService,
  LaravelConventionCategoryService,
  LaravelDepartmentService,
  LaravelECPService,
  LaravelEmployerService,
  LaravelExerciseFormService,
  LaravelExperienceService,
  LaravelMasterTitleService,
  LaravelPaymentTypeService,
  LaravelProductBrandService,
  LaravelProductCategoryService,
  LaravelProductQuantityService,
  LaravelProductWeightService,
  LaravelProfessionalSkillService,
  LaravelProfessionService,
  LaravelProvinceService,
  LaravelQualificationService,
  LaravelRegionService,
  LaravelSpecializationService,
  LaravelTreatedPathologyService,
  LaravelUserCategoryService,
} from './../../commons/services/backend/laravel-listing.service';

@Injectable()
export class ListingEffects {

  loadError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadListingFailed),
      tap(({ entityType, error }) => {
        if (error) {
          this.alertService.showErrorMessage(`Errore caricamento ${entityType}`, error);
        }
      })
    ), { dispatch: false }
  );

  storeError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeListingFailed),
      tap(({ entityType, error }) => {
        if (error) {
          this.alertService.showErrorMessage(`Errore salvataggio ${entityType}`, error);
        }
      })
    ), { dispatch: false }
  );

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadAll),
      switchMap(() => [
        ListingActions.loadDepartments(),
        ListingActions.loadProfessions(),
        ListingActions.loadEmployers(),
        ListingActions.loadQualifications(),
        ListingActions.loadUserCategories(),
        ListingActions.loadSpecializations(),
        ListingActions.loadRegions(),
        ListingActions.loadProvinces(),
        ListingActions.loadExerciseForms(),
        ListingActions.loadTreatedPatologies(),
        ListingActions.loadProfessionalSkills(),
        ListingActions.loadExperiences(),
        ListingActions.loadBrands(),
        ListingActions.loadQuantitites(),
        ListingActions.loadProductCategories(),
        ListingActions.loadWeights(),
        ListingActions.loadMasterTitles(),
        ListingActions.loadECPs(),
        ListingActions.loadAgreements(),
        ListingActions.loadPaymentTypes(),
        ListingActions.loadConventionCategories(),
        ListingActions.loadCertificateTypes()
      ])
    ));

  //TODO mrosetti - loadAllCompleted$

  loadDepartments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadDepartments),
      switchMap(() => this.laravelDepartmentService.list().pipe(
        map(result => ListingActions.loadDepartmentsCompleted({ departments: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "departments", error })))
      )))
  );

  loadProfessions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadProfessions),
      switchMap(() => this.laravelProfessionService.list().pipe(
        map(result => ListingActions.loadProfessionsCompleted({ professions: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "professions", error })))
      )))
  );

  loadQualifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadQualifications),
      switchMap(() => this.laravelQualificationService.list().pipe(
        map(result => ListingActions.loadQualificationsCompleted({ qualifications: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "qualifications", error })))
      )))
  );

  loadUserCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadUserCategories),
      switchMap(() => this.laravelUserCategoryService.list().pipe(
        map(result => ListingActions.loadUserCategoriesCompleted({ user_categories: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "user_categories", error })))
      )))
  );

  loadSpecializations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadSpecializations),
      switchMap(() => this.laravelSpecializationService.list().pipe(
        map(result => ListingActions.loadSpecializationsCompleted({ specializations: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "specializations", error })))
      )))
  );

  loadRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadRegions),
      switchMap(() => this.laravelRegionService.list().pipe(
        map(result => ListingActions.loadRegionsCompleted({ regions: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "regions", error })))
      )))
  );

  loadProvinces$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ListingActions.loadProvinces),
    switchMap(() => this.laravelProvinceService.list().pipe(
      map(result => ListingActions.loadProvincesCompleted({ provinces: result.data })),
      catchError(error => of(ListingActions.loadListingFailed({ entityType: "provinces", error })))
    )))
);

  loadEmployers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadEmployers),
      switchMap(() => this.laravelEmployerService.list().pipe(
        map(result => ListingActions.loadEmployersCompleted({ employers: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "employers", error })))
      )))
  );

  loadExerciseForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadExerciseForms),
      switchMap(() => this.laravelExerciseFormService.list().pipe(
        map(result => ListingActions.loadExerciseFormsCompleted({ exercise_forms: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "exercise_forms", error })))
      )))
  );

  loadTreatePathologies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadTreatedPatologies),
      switchMap(() => this.laravelTreatedPathologiesService.list().pipe(
        map(result => ListingActions.loadTreatedPatologiesCompleted({ treated_pathologies: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "treated_pathologies", error })))
      )))
  );

  loadProfessionalSkills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadProfessionalSkills),
      switchMap(() => this.laravelProfessionalSkillService.list().pipe(
        map(result => ListingActions.loadProfessionalSkillsCompleted({ professional_skills: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "professional_skills", error })))
      )))
  );


  loadExperiences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadExperiences),
      switchMap(() => this.laravelExperienceService.list().pipe(
        map(result => ListingActions.loadExperiencesCompleted({ experiences: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "experiences", error })))
      )))
  );

  loadBrands$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadBrands),
      switchMap(() => this.laravelProductBrandService.list().pipe(
        map(result => ListingActions.loadBrandsCompleted({ brands: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "brands", error })))
      )))
  );

  loadProductCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadProductCategories),
      switchMap(() => this.laravelProductCategoryService.list().pipe(
        map(result => ListingActions.loadProductCategoriesCompleted({ product_categories: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "product_categories", error })))
      )))
  );

  loadQuantities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadQuantitites),
      switchMap(() => this.laravelProductQuantityService.list().pipe(
        map(result => ListingActions.loadQuantititesCompleted({ quantities: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "quantities", error })))
      )))
  );

  loadWeights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadWeights),
      switchMap(() => this.laravelProductWeightService.list().pipe(
        map(result => ListingActions.loadWeightsCompleted({ weights: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "weights", error })))
      )))
  );

  loadMasterTitles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadMasterTitles),
      switchMap(() => this.laravelMasterTitleService.list().pipe(
        map(result => ListingActions.loadMasterTitlesCompleted({ master_titles: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "mastertitles", error })))
      )))
  );

  loadECPs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadECPs),
      switchMap(() => this.laravelECPService.list().pipe(
        map(result => ListingActions.loadECPsCompleted({ ecps: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "ecps", error })))
      )))
  );

  loadAgreements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadAgreements),
      switchMap(() => this.laravelAgreementService.list().pipe(
        map(result => ListingActions.loadAgreementsCompleted({ agreements: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "agreements", error })))
      )))
  );

  loadPaymentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadPaymentTypes),
      switchMap(() => this.laravelPaymentTypeService.list().pipe(
        map(result => ListingActions.loadPaymentTypesCompleted({ payment_types: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "paymenttypes", error })))
      )))
  );

  loadConventionCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadConventionCategories),
      switchMap(() => this.laravelConventionCategoryService.list().pipe(
        map(result => ListingActions.loadConventionCategoriesCompleted({ convention_categories: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "conventioncategories", error })))
      )))
  );

  loadCertificateTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.loadCertificateTypes),
      switchMap(() => this.laravelCertificateTypeService.list().pipe(
        map(result => ListingActions.loadCertificateTypesCompleted({ certificate_types: result.data })),
        catchError(error => of(ListingActions.loadListingFailed({ entityType: "certificatetypes", error })))
      )))
  );


  saveDepartments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeDepartments),
      switchMap(({ departments }) => this.laravelDepartmentService.storeAll(departments).pipe(
        map(departments => ListingActions.storeDepartmentsCompleted({ departments })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "departments", error })))
      )))
  );

  saveProfessions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeProfessions),
      switchMap(({ professions }) => this.laravelProfessionService.storeAll(professions).pipe(
        map(professions => ListingActions.storeProfessionsCompleted({ professions })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "professions", error })))
      )))
  );

  savePublicEmployers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storePublicEmployers),
      switchMap(({ employers }) => this.laravelEmployerService.storeAllPublic(employers).pipe(
        map(employers => ListingActions.storePublicEmployersCompleted({ employers })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "employers", error })))
      )))
  );

  savePrivateEmployers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storePrivateEmployers),
      switchMap(({ employers }) => this.laravelEmployerService.storeAllPrivate(employers).pipe(
        map(employers => ListingActions.storePrivateEmployersCompleted({ employers })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "employers", error })))
      )))
  );

  saveQualifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeQualifications),
      switchMap(({ qualifications }) => this.laravelQualificationService.storeAll(qualifications).pipe(
        map(qualifications => ListingActions.storeQualificationsCompleted({ qualifications })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "qualifications", error })))
      )))
  );

  saveUserCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeUserCategories),
      switchMap(({ user_categories }) => this.laravelUserCategoryService.storeAll(user_categories).pipe(
        map(user_categories => ListingActions.storeUserCategoriesCompleted({ user_categories })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "user_categories", error })))
      )))
  );

  saveSpecializations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeSpecializations),
      switchMap(({ specializations }) => this.laravelSpecializationService.storeAll(specializations).pipe(
        map(specializations => ListingActions.storeSpecializationsCompleted({ specializations })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "specializations", error })))
      )))
  );

  saveRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeRegions),
      switchMap(({ regions }) => this.laravelRegionService.storeAll(regions).pipe(
        map(regions => ListingActions.storeRegionsCompleted({ regions })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "regions", error })))
      )))
  );

  saveProvincess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ListingActions.storeProvinces),
    switchMap(({ provinces }) => this.laravelProvinceService.storeAll(provinces).pipe(
      map(provinces => ListingActions.storeProvincesCompleted({ provinces })),
      catchError(error => of(ListingActions.storeListingFailed({ entityType: "provinces", error })))
    )))
);

  saveExerciseForms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeExerciseForms),
      switchMap(({ exercise_forms }) => this.laravelExerciseFormService.storeAll(exercise_forms).pipe(
        map(exercise_forms => ListingActions.storeExerciseFormsCompleted({ exercise_forms })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "exercise_forms", error })))
      )))
  );

  saveTreatedPathologies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeTreatedPatologies),
      switchMap(({ treated_pathologies }) => this.laravelTreatedPathologiesService.storeAll(treated_pathologies).pipe(
        map(treated_pathologies => ListingActions.storeTreatedPatologiesCompleted({ treated_pathologies })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "treated_pathologies", error })))
      )))
  );

  saveProfessionalSkills$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeProfessionalSkills),
      switchMap(({ professional_skills }) => this.laravelProfessionalSkillService.storeAll(professional_skills).pipe(
        map(professional_skills => ListingActions.storeProfessionalSkillsCompleted({ professional_skills })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "professional_skills", error })))
      )))
  );

  saveExperiences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeExperiences),
      switchMap(({ experiences }) => this.laravelExperienceService.storeAll(experiences).pipe(
        map(experiences => ListingActions.storeExperiencesCompleted({ experiences })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "experiences", error })))
      )))
  );

  saveBrands$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeBrands),
      switchMap(({ brands }) => this.laravelProductBrandService.storeAll(brands).pipe(
        map(brands => ListingActions.storeBrandsCompleted({ brands })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "brands", error })))
      )))
  );

  saveProductCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeProductCategories),
      switchMap(({ product_categories }) => this.laravelProductCategoryService.storeAll(product_categories).pipe(
        map(product_categories => ListingActions.storeProductCategoriesCompleted({ product_categories })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "product_categories", error })))
      )))
  );

  saveQuantities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeQuantitites),
      switchMap(({ quantities }) => this.laravelProductQuantityService.storeAll(quantities).pipe(
        map(quantities => ListingActions.storeQuantititesCompleted({ quantities })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "quantities", error })))
      )))
  );

  saveWeights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeWeights),
      switchMap(({ weights }) => this.laravelProductWeightService.storeAll(weights).pipe(
        map(weights => ListingActions.storeWeightsCompleted({ weights })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "weights", error })))
      )))
  );

  saveMasterTitles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeMasterTitles),
      switchMap(({ master_titles }) => this.laravelMasterTitleService.storeAll(master_titles).pipe(
        map(master_titles => ListingActions.storeMasterTitlesCompleted({ master_titles })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "mastertitles", error })))
      )))
  );

  saveECPs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeECPs),
      switchMap(({ ecps }) => this.laravelECPService.storeAll(ecps).pipe(
        map(ecps => ListingActions.storeECPsCompleted({ ecps })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "ecps", error })))
      )))
  );

  saveAgreements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeAgreements),
      switchMap(({ agreements }) => this.laravelAgreementService.storeAll(agreements).pipe(
        map(agreements => ListingActions.storeAgreementsCompleted({ agreements })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "agreements", error })))
      )))
  );

  savePaymentTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storePaymentTypes),
      switchMap(({ payment_types }) => this.laravelPaymentTypeService.storeAll(payment_types).pipe(
        map(payment_types => ListingActions.storePaymentTypesCompleted({ payment_types })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "paymenttypes", error })))
      )))
  );

  saveConventionCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeConventionCategories),
      switchMap(({ convention_categories }) => this.laravelConventionCategoryService.storeAll(convention_categories).pipe(
        map(convention_categories => ListingActions.storeConventionCategoriesCompleted({ convention_categories })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "conventioncategories", error })))
      )))
  );

  saveCertificateTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListingActions.storeCertificateTypes),
      switchMap(({ certificate_types }) => this.laravelCertificateTypeService.storeAll(certificate_types).pipe(
        map(certificate_types => ListingActions.storeCertificateTypesCompleted({ certificate_types })),
        catchError(error => of(ListingActions.storeListingFailed({ entityType: "certificatetypes", error })))
      )))
  );

  constructor(
    private actions$: Actions,
    private alertService: AlertService,
    private laravelDepartmentService: LaravelDepartmentService,
    private laravelProfessionService: LaravelProfessionService,
    private laravelQualificationService: LaravelQualificationService,
    private laravelUserCategoryService: LaravelUserCategoryService,
    private laravelSpecializationService: LaravelSpecializationService,
    private laravelRegionService: LaravelRegionService,
    private laravelProvinceService: LaravelProvinceService,
    private laravelEmployerService: LaravelEmployerService,
    private laravelExerciseFormService: LaravelExerciseFormService,
    private laravelTreatedPathologiesService: LaravelTreatedPathologyService,
    private laravelProfessionalSkillService: LaravelProfessionalSkillService,
    private laravelExperienceService: LaravelExperienceService,
    private laravelProductBrandService: LaravelProductBrandService,
    private laravelProductCategoryService: LaravelProductCategoryService,
    private laravelProductQuantityService: LaravelProductQuantityService,
    private laravelProductWeightService: LaravelProductWeightService,
    private laravelMasterTitleService: LaravelMasterTitleService,
    private laravelECPService: LaravelECPService,
    private laravelAgreementService: LaravelAgreementService,
    private laravelPaymentTypeService: LaravelPaymentTypeService,
    private laravelConventionCategoryService: LaravelConventionCategoryService,
    private laravelCertificateTypeService: LaravelCertificateTypeService,
  ) { }
}
