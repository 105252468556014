import { Action, createReducer, on } from '@ngrx/store';

import { GlossaryTermDTO, GlossaryTermFilters } from '../../commons/models/glossary.model';
import * as GlossaryActions from '../actions/glossary.actions';

export interface GlossaryState {
  terms: GlossaryTermDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: GlossaryTermFilters,
  dialogId: string
};

const initialState: GlossaryState = {
  terms: null,
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null
};

const glossaryReducer = createReducer(
  initialState,
  on(GlossaryActions.loadGlossaryCompleted, (state, { terms, currentPage, total, perPage, order, direction, filters }) => {
    return { ...state, terms, currentPage, total, perPage, order, direction, filters };
  }),
  on(GlossaryActions.glossaryDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(GlossaryActions.changeFilters, (state, { filters }) => {
    return { ...state, filters };
  })
);

export function reducer(state: GlossaryState | undefined, action: Action) {
  return glossaryReducer(state, action);
}

