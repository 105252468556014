import { createAction, props } from '@ngrx/store';

import { Product, ProductDTO, ProductFilters } from '../../commons/models/product.model';

export const loadProducts = createAction('[Product] Load products', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: ProductFilters }>());
export const loadProductsCompleted = createAction('[Product] Load products Completed', props<{ products: ProductDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ProductFilters }>());
export const loadProductsFailed = createAction('[Product] Load products Failed', props<{ error: any }>());

export const changePage = createAction('[Product] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Product] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Product] Change filters', props<{ filters: ProductFilters }>());

export const editProduct = createAction('[Product] Edit product', props<{ product: Product }>());
export const productDialogOpened = createAction('[Product] Detail dialog opened', props<{ dialogId: string }>());
export const closeProductDialog = createAction('[Product] Close detail dialog');

export const saveProduct = createAction('[Product] Save product', props<{ product: Product }>());
export const saveProductCompleted = createAction('[Product] Save product completed', props<{ product: ProductDTO }>());
export const saveProductFailed = createAction('[Product] Save product failed', props<{ error: any }>());

export const deleteProduct = createAction('[Product] Delete product', props<{ product: Product }>());
export const deleteProductCompleted = createAction('[Product] Delete product completed', props<{ product: ProductDTO }>());
export const deleteProductCancelled = createAction('[Product] Delete product cancelled');
export const deleteProductFailed = createAction('[Product] Delete product failed', props<{ error: any }>());
