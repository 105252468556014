import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Role } from 'src/app/commons/models/role.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as RoleActions from 'src/app/store/actions/role.actions';
import { AppState } from 'src/app/store/reducers';
import * as RoleSelectors from 'src/app/store/selectors/role.selectors';

import { Permission } from './../../../../commons/models/role.model';

@Component({
  selector: 'admin-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss']
})
export class RoleEditComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  private _role: Role;

  permissions: Permission[];

  roleForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this._createForm();

    this.store$.pipe(select(RoleSelectors.getPermissions), takeUntil(this.unsubscribe$)).subscribe(results => this.permissions = results ? results.map(p => new Permission(p)) : []);
  }

  ngOnInit() {
    if (this.data) {
      this.role = this.data.role;
    }
    this._loadAll();
  }

  private _loadAll() {
    this.store$.dispatch(RoleActions.loadPermissions());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      name: ["", Validators.required],
      permissions: [""]
    }

    this.roleForm = this.fb.group(group);
  }

  checkboxChanged(event: MatCheckboxChange, permission: Permission) {
    console.log(event);
    event.checked ? this._addPermission(permission) : this._removePermission(permission);
  }

  private _addPermission(permission: Permission) {
    const array: Permission[] = this.roleForm.get("permissions").value || [];
    if (!array.find((p) => p.id == permission.id)) {
      this.roleForm.patchValue({
        permissions: array.concat(permission)
      })
      this.roleForm.markAsDirty();
    }
  }

  private _removePermission(permission: Permission) {
    const array: Permission[] = this.roleForm.get("permissions").value || [];
    if (array.find((p) => p.id == permission.id)) {
      this.roleForm.patchValue({
        permissions: array.filter(p => p.id != permission.id)
      })
      this.roleForm.markAsDirty();
    }
  }

  private ngOnChanges() {
    if (this.roleForm) {
      this.roleForm.reset();
      if (this.role) {
        this._initFormValues(this.role);
      }
    }
  }

  private _initFormValues(role: Role) {
    if (role) {
      this.roleForm.patchValue({
        name: role.name,
        permissions: role.permissions,
      });
    }
  }

  isPermissionSelected(permission): boolean {
    return this.roleForm && this.roleForm.get('permissions') && this.roleForm.get('permissions').value && !!this.roleForm.get('permissions').value.find(p => p.id == permission.id)
  }

  get role(): Role {
    return this._role;
  }

  set role(role: Role) {
    this._role = role;
    this.ngOnChanges();
  }

  private _prepareSaveRole(): Role {
    let savingRole: Role = Role.fromFormGroup(this.roleForm, this.role);
    return savingRole;
  }

  save() {
    let unsavedEntity = this._prepareSaveRole();
    this.store$.dispatch(RoleActions.saveRole({ role: unsavedEntity }));
  }

  deleteRole() {
    this.store$.dispatch(RoleActions.deleteRole({ role: this.role }));
  }

  close() {
    if (this.roleForm.pristine) {
      this.store$.dispatch(RoleActions.closeRoleDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(RoleActions.closeRoleDialog())
          }
        });
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
