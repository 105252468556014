<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="role">Modifica ruolo: {{role.name}}</span>
    <span *ngIf="!role">Nuovo ruolo</span>
  </span>
  <span *ngIf="role">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteRole()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="roleForm" novalidate>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Nome" formControlName="name" type="text" required>
      <mat-error>Obbligatorio</mat-error>
    </mat-form-field>
    <mat-label>Permessi</mat-label>
    <mat-checkbox *ngFor="let permission of permissions" [checked]="isPermissionSelected(permission)"
      (change)="checkboxChanged($event, permission)">
      {{permission.name}}</mat-checkbox>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="roleForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="roleForm.pristine || !roleForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
