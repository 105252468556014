import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import * as GlossaryActions from 'src/app/store/actions/glossary.actions';
import { AppState } from 'src/app/store/reducers';
import * as GlossarySelectors from 'src/app/store/selectors/glossary.selectors';

import { GlossaryTerm } from './../../../commons/models/glossary.model';

@Component({
  selector: 'admin-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => HtmlEditorComponent) }
  ]
})
export class HtmlEditorComponent implements ControlValueAccessor {
  private unsubscribe$ = new Subject<void>();
  glossaryTerms: GlossaryTerm[];

  unique = this.constructor['ɵcmp'].id;

  options: any = {
    pluginsEnabled: ["glossary", "align", "codeView", "fontSize", "lineBreaker", "link", "lists", "paragraphFormat", "paragraphStyle", "table", "url"],
    language: 'it',
    toolbarButtons: {
      moreText: {
        buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'formatOL', 'formatUL', 'clearFormatting', 'insertTable', "insertGlossary", "glossaryRemove", 'insertLink', 'linkRemove'],
        align: "left",
        buttonsVisible: 6
      },
      moreMisc: {
        buttons: ['undo', 'redo', 'html'],
        align: "right"
      }
    },

    placeholderText: 'Scrivi qualcosa...',
  };

  constructor(private store: Store<AppState>) {
    this.store.pipe(
      select(GlossarySelectors.getGlossaryTerms),
      takeUntil(this.unsubscribe$),
      map(dtos => dtos ? dtos.map(dto => new GlossaryTerm(dto)) : null),
      tap(terms => {
        if (!terms) {
          this.store.dispatch(GlossaryActions.loadGlossary({ page: 1, perPage: 99999999 }))
        }
      })
    ).subscribe(terms => this.glossaryTerms = terms);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get froalaOptions() {
    return JSON.parse(JSON.stringify(this.options));
  }

  private _value: string;
  disabled: boolean;

  onChange: any = () => { }
  onTouch: any = () => { }


  writeValue(value: string): void {
    this.value = value;
  }

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    if (value !== undefined && this.value !== value) {
      this._value = value;
      this.onChange(value);
      this.onTouch();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
