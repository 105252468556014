import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, flatMap, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AlertService } from 'src/app/commons/services/alert.service';
import {
  ChangePasswordDialogComponent,
} from 'src/app/modules/shared/change-password-dialog/change-password-dialog.component';

import { LaravelUserService } from '../../commons/services/backend/laravel-user.service';
import * as AuthActions from '../actions/auth.actions';
import { AppState } from '../reducers';
import { getChangePasswordDialogId, getCurrentUser } from './../selectors/auth.selectors';

@Injectable()
export class AuthEffects {

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      mergeMap(({ username, password }) =>
        this.laravelUserService.login(username, password)
          .pipe(
            map(result => {
              if (result.user.user_roles.includes('admin')) {
                return AuthActions.loginCompleted({ currentUser: result.user, token: result.access_token })
              } else {
                return AuthActions.loginFailed({ error: 'Ruolo non valido' });
              }
            }),
            catchError(error => of(AuthActions.loginFailed({ error }))))
      )),
    { useEffectsErrorHandler: false }
  );

  afterLogin$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginCompleted),
    tap(() => {
      this.router.navigate(['/']);
      this.alertService.showConfirmMessage(`Login effettuato con successo`)
    })), { dispatch: false },
  );

  loginError$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginFailed),
    tap((action) => this.alertService.showErrorMessage(`Errore durante il login`, action.error))
  ), { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      mergeMap(({ showConfirm }) => {
        if (showConfirm) {
          return this.alertService.showConfirmDialog('Conferma', `Sei sicuro di voler uscire?`)
            //mrosetti - Nothing to do so far, just relaunch logoutCompleted action
            .pipe(
              map((confirm) => {
                return confirm ? AuthActions.logoutCompleted({ silent: false }) : AuthActions.logoutCancelled();
              })
            )
        } else {
          return of(AuthActions.logoutCompleted({ silent: true }));
        }
      })),
    { useEffectsErrorHandler: false }
  );

  afterLogout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logoutCompleted),
    tap(() => this.router.navigate(['login'])),
    filter(({ silent }) => !silent),
    tap(() => this.alertService.showConfirmMessage(`Logout effettuato con successo`))

  ), { dispatch: false }
  );

  currentUser$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loadCurrentUser),
    mergeMap(() =>
      this.laravelUserService.getCurrentUser()
        .pipe(
          map((currentUser) => AuthActions.loadCurrentUserCompleted({ currentUser })),
          catchError(error => of(AuthActions.loadCurrentUserFailed({ error }))))
    )
  ));

  changePassword$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.changeUserPassword),
    map(({ user }) => {
      let dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
        data: {
          user
        }
      });
      return AuthActions.changePasswordDialogOpen({ dialogId: dialogRef.id });
    }))
  );

  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updatePassword),
      flatMap(({ newPassword, user }) => {
        if (user) {
          return of({ newPassword, user });
        } else {
          return this.store$.select(getCurrentUser).pipe(take(1), map(user => {
            return { newPassword, user }
          }));
        }
      }),
      switchMap(({ newPassword, user }) => {
        return this.laravelUserService.changePassword(user.id, newPassword).pipe(
          map(user =>
            AuthActions.updatePasswordCompleted({ user })
          ),
          catchError(error => {
            return of(AuthActions.updatePasswordFailed({ error }))
          })
        )
      })
    )
  );

  onUpdatePasswordCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updatePasswordCompleted),
      map(action => action.user),
      tap(user => this.alertService.showConfirmMessage(`Password per ${user.email} aggiornata con successo`)),
      map(() => AuthActions.closeChangePasswordDialog())
    )
  );

  closePasswordDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.closeChangePasswordDialog),
      withLatestFrom(this.store$.select(getChangePasswordDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private laravelUserService: LaravelUserService,
    private alertService: AlertService,
    private store$: Store<AppState>,
    private router: Router,
    private dialog: MatDialog,
  ) { }
}
