import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AlertService } from '../../commons/services/alert.service';
import { GlossaryEditComponent } from '../../modules/home/glossary/glossary-edit/glossary-edit.component';
import * as GlossaryActions from '../actions/glossary.actions';
import { AppState } from '../reducers';
import { getGlossaryDialogId, getGlossaryTermsTableState } from '../selectors/glossary.selectors';
import { LaravelGlossaryService } from './../../commons/services/backend/laravel-glossary.service';

@Injectable()
export class GlossaryEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlossaryActions.saveGlossaryFailed, GlossaryActions.deleteGlossaryFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), { dispatch: false }
  );

  loadGlossary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlossaryActions.loadGlossary),
      switchMap(({ page, perPage, order, direction, filters }) => {
        return this.glossaryService.list(page, perPage, order, direction, filters)
          .pipe(
            map(result =>
              GlossaryActions.loadGlossaryCompleted({ terms: result.data, currentPage: page, total: result.total, perPage, order, direction, filters })
            ),
            catchError(error => {
              return of(GlossaryActions.loadGlossaryFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(GlossaryActions.changePage),
      withLatestFrom(this.store$.select(getGlossaryTermsTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters }]) => GlossaryActions.loadGlossary({ page: page, perPage: size, order, direction, filters }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(GlossaryActions.changeSort),
      withLatestFrom(this.store$.select(getGlossaryTermsTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters }]) => GlossaryActions.loadGlossary({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters }))
    )
  );

  editUser$ = createEffect(() => this.actions$.pipe(
    ofType(GlossaryActions.editGlossary),
    map(({ glossary }) => {
      let dialogRef = this.dialog.open(GlossaryEditComponent, {
        width: "85%",
        data: {
          glossary
        }
      });
      return GlossaryActions.glossaryDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  saveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlossaryActions.saveGlossary),
      mergeMap(({ glossary }) =>
        this.glossaryService.upsert(glossary.toDTO())
          .pipe(
            map(result =>
              GlossaryActions.saveGlossaryCompleted({ glossary: result })
            ),
            catchError(error => of(GlossaryActions.saveGlossaryFailed({ error })))
          )
      )
    )
  );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlossaryActions.saveGlossaryCompleted),
      map(action => action.glossary),
      tap(glossary => this.alertService.showConfirmMessage(`Glossario ${glossary.word} salvato con successo`)),
      map(() => GlossaryActions.closeGlossaryDialog())
    )
  );

  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(GlossaryActions.closeGlossaryDialog),
      withLatestFrom(this.store$.select(getGlossaryDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(GlossaryActions.saveGlossaryCompleted, GlossaryActions.deleteGlossaryCompleted),
      withLatestFrom(this.store$.select(getGlossaryTermsTableState)),
      map(([_, { total, currentPage, perPage, direction, order, filters }]) => GlossaryActions.loadGlossary({ page: currentPage, perPage, order, direction, filters }))
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlossaryActions.deleteGlossary),
      map(action => action.glossary),
      mergeMap((glossary) =>
        this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare la voce ${glossary.word}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.glossaryService.delete(glossary.id)
                  .pipe(
                    map(() => GlossaryActions.deleteGlossaryCompleted({ glossary: glossary.toDTO() })),
                    catchError(error => of(GlossaryActions.deleteGlossaryFailed({ error })))
                  )
                : of(GlossaryActions.deleteGlossaryCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlossaryActions.deleteGlossaryCompleted),
      map(action => action.glossary),
      tap(glossary => this.alertService.showConfirmMessage(`Voce ${glossary.word} eliminata con successo`)),
      map(() => GlossaryActions.closeGlossaryDialog())
    )
  );


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private glossaryService: LaravelGlossaryService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}
