import { Action, createReducer, on } from '@ngrx/store';

import { ProductDTO, ProductFilters } from '../../commons/models/product.model';
import * as ProductActions from '../actions/product.actions';

export interface ProductState {
  products: ProductDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: ProductFilters,
  dialogId: string
};

const initialState: ProductState = {
  products: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null
};

const productReducer = createReducer(
  initialState,
  on(ProductActions.loadProductsCompleted, (state, { products, currentPage, total, perPage, order, direction, filters }) => {
    return { ...state, products, currentPage, total, perPage, order, direction, filters };
  }),
  on(ProductActions.productDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ProductActions.changeFilters, (state, { filters }) => {
    return { ...state, filters };
  })
);

export function reducer(state: ProductState | undefined, action: Action) {
  return productReducer(state, action);
}

