<header mat-dialog-title class="u-flex-full">
  <span>
    Modifica password <span *ngIf="user"> per {{user.fullName}}</span>
  </span>
</header>
<form [formGroup]="passwordForm" novalidate>
  <mat-dialog-content>
    <span class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input type="password" name="password" placeholder="Nuova Password" matInput formControlName="password"
          required>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input type="password" name="confirmPass" placeholder="Conferma password" matInput
          formControlName="confirmPass">
      </mat-form-field>
    </span>
    <mat-error *ngIf="passwordForm.hasError('notEquivalent')" class="u-full-width u-center-text">Le
      password inserite non coincidono</mat-error>
  </mat-dialog-content>
  <mat-dialog-actions class="u-flex-full">
    <span>
      <button mat-raised-button color="primary" (click)="save()"
        [disabled]="passwordForm.pristine || !passwordForm.valid">Conferma</button>
      <span class="fill-remaining"></span>
    </span>
    <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
  </mat-dialog-actions>
</form>
